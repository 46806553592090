import React from "react";
import Zoom from "react-reveal/Zoom";
import { Link } from "react-router-dom";
import Bounce from "react-reveal/Bounce";

export default function Culture() {
  return (
    <div className="culture-area long-section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="culture-img">
              <Zoom>
                <img src="img/culture-img-one.jpg" alt="culture-img" />

                <img src="img/culture-img-three.jpg" alt="culture-img" />
              </Zoom>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="culture-img-two">
              <Zoom>
                <img src="img/culture-img-two.jpg" alt="culture-img" />
              </Zoom>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-title our-culture-title-margin our-culture-mt-20">
              <h2>Our Culture</h2>
              <h6 className="mt-5 mb-5">
                The ethos of LDS is one of constant and purposeful
                collaboration. Our employees' daily work practices and mindsets
                are infused with a spirit of collaboration. We cherish the
                concept that we are stronger when we work together. Collective
                intelligence drives the most innovative solutions. LDS is a firm
                believer in the power of teamwork to spur new ideas by bringing
                forth the best in everyone involved. Team members are more
                energized, productive, and flexible when they feel like they are
                contributing to a collective endeavor. When people work
                together, they develop a sense of belonging and participation.
                It has a positive effect on our mood and our stress levels. We
                believe that people with a positive - get things done - learning
                attitude is the foundation of a successful organisation.
              </h6>
              <div className="culture-buttonn">
                <Link to="/career">
                  <Bounce top>
                    <button>
                      Join Our Team
                      <i className="fas fa-arrow-right"></i>
                    </button>
                  </Bounce>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="licence-area text-center">
              <h3> Company Licenses</h3>
              <br></br> Trade License: TRAD/DNCC003220/2022<br></br> TIN:
              1402939070
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
