import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../home/Footer";
import NavbarTwo from "../home/NavbarTwo";

export default function BlogDetails({ blogList }) {
  const [blogDetails, setblogDetails] = useState([]);

  const { id } = useParams();

  console.log("blogDetails", blogDetails);

  const fetchBlogDetails = useCallback(() => {
    if (true) {
      try {
        fetch(
          `https://prod-reader-api.london-design-studios.com/api/v1/blogs/details/${id}`
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setblogDetails(data?.details);
          })
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error, "error");
      }
    }
  }, [id]);
  useEffect(() => {
    fetchBlogDetails();
  }, [fetchBlogDetails]);
  const picPath = `https://dgarma.s3.ap-southeast-1.amazonaws.com/public/blogs/${blogDetails?.thumbnail}`;
  return (
    <>
      <NavbarTwo />
      <div class="blog-details-area p-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 mb-3">
              <div class="blog-details-card rounded p-3">
                <div className="blog-details-title mb-4">
                  <h1>{blogDetails?.title}</h1>
                </div>
                <div class="single-blog-card-img">
                  <img src={picPath} alt="blog" />
                </div>

                <div className="mt-2">
                  <p>
                    <b>{moment(blogDetails?.createdAt).format("LL")}</b>
                  </p>
                </div>
                <div class="blog-details-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blogDetails?.description,
                    }}
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-3 mb-3">
              <div class="blog-details-card rounded p-3">
                {blogList.slice(0, 3)?.map((item, index) => {
                  let picPath = `https://dgarma.s3.ap-southeast-1.amazonaws.com/public/blogs/${item?.thumbnail}`;
                  return (
                    <Link to={`/blog-details/${item._id}`}>
                      <div key={index}>
                        <div className="blog-details-left-sidebar-title ">
                          <h5>{item?.title}</h5>
                        </div>
                        <div className="mb-2">
                          <p className="mt-2">
                            <b>{moment(item?.createdAt).format("LL")}</b>
                          </p>
                        </div>
                        <div class="single-blog-card-img">
                          <img src={picPath} alt="blog" />
                        </div>

                        <hr />
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
