import React from "react";
import Navbar from "./Navbar";
import Bounce from "react-reveal/Bounce";
import { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const TopMenuBar = () => {
  const [totalstock, setTotalstock] = useState("");
  const [numberofstock, setNumberofstock] = useState("");
  const MANUFACTURER = () => {
    if (totalstock) {
      const items = totalstock;

      const anotherItems = items.map(
        (item) => item._id === "MANUFACTURER" && item.total
      );
      if (anotherItems) {
        const result = anotherItems.filter((word) => word !== false);
        const result2 = +result.join("");

        return result2;
      }
    }
  };

  const FABRIC_SUPPLIER = () => {
    if (totalstock) {
      const items = totalstock;

      const anotherItems = items.map(
        (item) => item._id === "FABRIC_SUPPLIER" && item.total
      );
      if (anotherItems) {
        const result = anotherItems.filter((word) => word !== false);
        const result2 = +result.join("");

        return result2;
      }
    }
  };

  const finalres = MANUFACTURER() + FABRIC_SUPPLIER();

  const raiseInvoiceClicked = () => {
    localStorage.setItem("pageData", "Data Retrieved from axios request");
    window.open("https://app.dgarma.com/register", "_blank"); //to open new page
  };
  const partnerTypes = [
    "MANUFACTURER",
    "BUYER",
    "FABRIC_SUPPLIER",
    "DESIGNER",
    "BUYING_HOUSE",
    "ACCESSORIES_SUPPLIER",
  ];
  const fetchStockfabrics = useCallback(() => {
    if (true) {
      try {
        fetch(
          `${process.env.REACT_APP_READER}/partners/public-multiple-total`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({
              partnerTypes,
            }),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setTotalstock(data.details);
          })
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchStockfabrics();
  }, []);

  const fetchNumberofstock = useCallback(() => {
    if (true) {
      try {
        fetch(`${process.env.REACT_APP_READER}/products/public-no-of-stock`, {
          method: "GET",
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setNumberofstock(data.details);
          })
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchNumberofstock();
  }, []);

  console.log("numberofstock new", numberofstock);
  return (
    <>
      <ReactTooltip />
      <div className="top-menu-bar-area">
        <div className="container">
          <div className="row">
            <Navbar />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="top-menu-bar-content-title">
                <h1>
                  Source the Unsourced<br></br> Fabrics with Us
                </h1>
                <h6>
                  Connecting Buyers, Manufacturers & <br></br>Designers
                  Digitally
                </h6>
                <Bounce top>
                  <button onClick={raiseInvoiceClicked}>
                    Get Started
                    <i className="fas fa-arrow-right"></i>
                  </button>
                </Bounce>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="video-wrapper">
                {/*<video class="video-border" playsInline controls poster="img/thabnails.jpg" width="100%" height="100%"*/}
                {/*     preload="auto" >*/}
                {/*  <source src="video/ldsvideo.mp4" type="video/mp4" />*/}
                {/*</video>*/}
                <div className="videoPlayer video-border">
                  <iframe
                    src="https://player.vimeo.com/video/757563065?h=df90efd125&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="LDSL Platform for Apparel Buyers"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>
            </div>
          </div>
          <div class="top-menubar-item-wrapper">
            <div className="row d-flex">
              <hr></hr>
              <div class="col-lg-3 text-md-left text-center">
                {" "}
                {totalstock.length &&
                  totalstock?.map((doc, index) => {
                    return (
                      <>
                        {doc._id === "FABRIC_SUPPLIER" && (
                          <b>{finalres}+&nbsp;&nbsp;Verified Suppliers</b>
                        )}

                        {/* {doc._id === "BUYER" && (
                        <li>
                          <b>{doc.total}+&nbsp;&nbsp;BUYER</b>
                        </li>
                      )} */}
                      </>
                    );
                  })}
              </div>
              <div class="col-lg-3 text-md-left text-center">
                {" "}
                <Link to="/tech-pack">
                  <b
                    data-tip="Click here to see designs"
                    className="top-menubar-item-clr"
                  >
                    8+&nbsp;&nbsp; TeckPacks & Designs
                  </b>
                </Link>
              </div>
              <div class="col-lg-3 text-center text-md-left ">
                <Link to="/stock-fabrics">
                  <b
                    data-tip="Click here to see Stock"
                    className="top-menubar-item-clr"
                  >
                    {numberofstock.length &&
                      numberofstock.map((doc, index) => {
                        return (
                          <>
                            {doc._id?.unit === "YARD" &&
                              doc._id?.type === "FABRIC" && (
                                <>
                                  <b>
                                    {doc.totalAvailableStock}+&nbsp;&nbsp;Yards
                                  </b>
                                  <br></br>
                                </>
                              )}
                          </>
                        );
                      })}
                    {numberofstock.length &&
                      numberofstock.map((doc, index) => {
                        return (
                          <>
                            {doc._id?.unit === "KG" &&
                              doc._id?.type === "FABRIC" && (
                                <b>{doc.totalAvailableStock}+&nbsp;&nbsp;KG</b>
                              )}
                          </>
                        );
                      })}
                  </b>
                </Link>
              </div>
              <div class="col-lg-3 text-center text-md-right">
                {" "}
                <b>30% less sourcing time</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopMenuBar;
