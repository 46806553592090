import React from "react";
import Fade from "react-reveal/Fade";

const MoreValues = () => {
  return (
    <>
      <div className="more-values-area short-section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="more-values-title">
                <h4>FOR SUPPLIERS</h4>
                <h2 className="mb-10">Get More Value</h2>
                <p>Showcase your company & products to verified buyers</p>
              </div>
            </div>
          </div>
          <div className="row short-section-padding">
            <div className="col-lg-6">
              <div className="more-values-content">
                <div className="row">
                  <div className="col-lg-2 card-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="82"
                      height="82"
                      viewBox="0 0 82 82"
                    >
                      <defs>
                        <filter
                          id="Rectangle_86"
                          x="0"
                          y="0"
                          width="82"
                          height="82"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.071" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        id="Component_8_1"
                        data-name="Component 8 – 1"
                        transform="translate(9 6)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Rectangle_86)"
                        >
                          <rect
                            id="Rectangle_86-2"
                            data-name="Rectangle 86"
                            width="64"
                            height="64"
                            rx="8"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Icon_ionic-md-trending-up"
                          data-name="Icon ionic-md-trending-up"
                          d="M28.064,9l4.223,4.223-9,9.005-7.375-7.375L2.25,28.541l2.6,2.6L15.914,20.071l7.375,7.384L34.9,15.848l4.223,4.223V9Z"
                          transform="translate(11.312 11.929)"
                          fill="#6849e9"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="col-lg-10">
                    <div className="more-values-content-two">
                      <h4>Reduce inventories, Increase revenue</h4>
                      <p className="mt-2">
                        Utilizing unused fabric for garmenting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="more-values-content">
                <div className="row">
                  <div className="col-lg-2 card-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="82"
                      height="82"
                      viewBox="0 0 82 82"
                    >
                      <defs>
                        <filter
                          id="Rectangle_82"
                          x="0"
                          y="0"
                          width="82"
                          height="82"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.071" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        id="Component_9_1"
                        data-name="Component 9 – 1"
                        transform="translate(9 6)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Rectangle_82)"
                        >
                          <rect
                            id="Rectangle_82-2"
                            data-name="Rectangle 82"
                            width="64"
                            height="64"
                            rx="8"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Icon_payment-clickandbuy"
                          data-name="Icon payment-clickandbuy"
                          d="M50.385,23.974a2.993,2.993,0,0,1-.008,4.222l-6.749,6.757a2.986,2.986,0,0,1-5.1-2.111,3.279,3.279,0,0,1,.875-2.111l1.643-1.643h-6.3a3,3,0,0,1-3.01-3,3.015,3.015,0,0,1,3.01-3h6.3l-1.643-1.636a3.277,3.277,0,0,1-.875-2.111,2.986,2.986,0,0,1,5.1-2.111l6.757,6.749Zm-24.315-2.05a3.984,3.984,0,0,0-3.984,3.984,3.984,3.984,0,0,0,3.984,3.984,3.984,3.984,0,0,0,3.984-3.984,3.984,3.984,0,0,0-3.984-3.984Z"
                          transform="translate(-4.668 7.251)"
                          fill="#d049e9"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="col-lg-10">
                    <div className="more-values-content-two more-values-content-two-special-padding-single">
                      <h4>Single click access to potential customers</h4>
                      <p className="mt-2">
                        Comprehensive database of International clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="more-values-content">
                <div className="row">
                  <div className="col-lg-2 card-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="82"
                      height="82"
                      viewBox="0 0 82 82"
                    >
                      <defs>
                        <filter
                          id="Rectangle_84"
                          x="0"
                          y="0"
                          width="82"
                          height="82"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.071" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        id="Component_10_1"
                        data-name="Component 10 – 1"
                        transform="translate(9 6)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Rectangle_84)"
                        >
                          <rect
                            id="Rectangle_84-2"
                            data-name="Rectangle 84"
                            width="64"
                            height="64"
                            rx="8"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Icon_simple-pivotaltracker"
                          data-name="Icon simple-pivotaltracker"
                          d="M18,0A18,18,0,1,0,36,18,18.018,18.018,0,0,0,18,0Zm0,3.645A14.355,14.355,0,1,1,3.648,18,14.352,14.352,0,0,1,18,3.645ZM17.8,5.61,13.233,8.247v0l4.02,6.96a2.877,2.877,0,0,1,1.965.177l2.115-3.663Zm6.255,3.384-4.02,6.964a2.868,2.868,0,0,1,.836,1.792H25.1l3.534-6.114L24.056,8.994ZM7.172,11.976V17.3h0v-.045h8.04a2.856,2.856,0,0,1,1.134-1.62l-2.114-3.66ZM10.9,18.249,7.368,24.36,11.941,27l4.023-6.968a2.893,2.893,0,0,1-.835-1.786Zm9.885.5a2.856,2.856,0,0,1-1.134,1.617l2.115,3.663h7.06v-5.28H20.785Zm-4,1.866-2.115,3.663,3.53,6.114,4.573-2.64-4.02-6.96a2.882,2.882,0,0,1-1.969-.177Z"
                          transform="translate(13.999 15.336)"
                          fill="#49b8e9"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="col-lg-10">
                    <div className="more-values-content-two more-values-content-two-special-padding">
                      <h4>Deliver Efficiency</h4>
                      <p className="mt-2">
                        Increased utilization of production capacities while
                        reducing operational costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="more-values-content">
                <div className="row">
                  <div className="col-lg-2 card-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="82"
                      height="82"
                      viewBox="0 0 82 82"
                    >
                      <defs>
                        <filter
                          id="Rectangle_87"
                          x="0"
                          y="0"
                          width="82"
                          height="82"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.071" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        id="Group_90"
                        data-name="Group 90"
                        transform="translate(-93 -3845)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, 93, 3845)"
                          filter="url(#Rectangle_87)"
                        >
                          <rect
                            id="Rectangle_87-2"
                            data-name="Rectangle 87"
                            width="64"
                            height="64"
                            rx="8"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Icon_simple-processwire"
                          data-name="Icon simple-processwire"
                          transform="translate(120.132 3869.133)"
                        >
                          <path
                            id="Icon_simple-processwire-2"
                            data-name="Icon simple-processwire"
                            d="M25.353,6.09A14.132,14.132,0,0,0,12.416.071,13.953,13.953,0,0,0,4.43,3.542,13.221,13.221,0,0,0,.462,9.7a13.335,13.335,0,0,0-.305,5.556,18.875,18.875,0,0,0,.972,3.385,1.1,1.1,0,0,0,.474.57,1.9,1.9,0,0,0,2.129-.736.194.194,0,0,0,.035-.169,15.857,15.857,0,0,1-.329-1.724,15.539,15.539,0,0,1-.121-3.593,10.469,10.469,0,0,1,.433-2.275,10.062,10.062,0,0,1,3-4.6,10.878,10.878,0,0,1,5.831-2.623,10.367,10.367,0,0,1,3.7.235,11.092,11.092,0,0,1,3.438,1.558,10.285,10.285,0,0,1,2.743,2.731,10.231,10.231,0,0,1,1.664,7.613,10.907,10.907,0,0,1-1.458,3.785,10.211,10.211,0,0,1-2.777,2.941,10.821,10.821,0,0,1-3.68,1.657,10.038,10.038,0,0,1-2,.277,11.181,11.181,0,0,1-1.961-.095c-.833-.133-1.007-.346-1.2-.634a1.772,1.772,0,0,1-.17-.765c.013-5.091.01-3.732.01-6.374,0-.747-.024-1.424-.017-2.039a3.2,3.2,0,0,1,.841-2.453,2.807,2.807,0,0,1,2.054-.888,2.7,2.7,0,0,1,2.778,2.431A3.16,3.16,0,0,1,15.441,16.2a4.026,4.026,0,0,1-1.112.589A4.716,4.716,0,0,1,12.56,17a.172.172,0,0,0-.183.138l-.191.989a.846.846,0,0,0,.474,1.123,5.849,5.849,0,0,0,2.278.277,5.7,5.7,0,0,0,3.527-1.555,5.167,5.167,0,0,0,1.57-3.057,6.792,6.792,0,0,0-.643-3.871,6.361,6.361,0,0,0-7.456-3.2l-.017.006A6.376,6.376,0,0,0,9.19,9.527a6.271,6.271,0,0,0-1.406,2.257,7.2,7.2,0,0,0-.4,2.525c-.018.747.017,1.44.017,2.089v4.277c0,1.368-.046,1.605,0,2.309A5.363,5.363,0,0,0,7.7,24.547a4.466,4.466,0,0,0,.955,1.562,4.524,4.524,0,0,0,1.494,1.04,10.052,10.052,0,0,0,4.2.573A13.755,13.755,0,0,0,17,27.36a14.117,14.117,0,0,0,4.894-2.216A13.61,13.61,0,0,0,25.6,21.222,14.172,14.172,0,0,0,27.53,16.2,13.667,13.667,0,0,0,25.309,6.048l.044.043Z"
                            transform="translate(0.001 -0.001)"
                            fill="#49e9c8"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="col-lg-10">
                    <div className="more-values-content-two">
                      <h4>Digitized Billing Process</h4>
                      <p className="mt-2">
                        Online management of the billing procedures.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="more-values-content more-values-content-Transparency-padding-responsive">
                <div class="row">
                  <div className="col-lg-2 card-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="82"
                      height="82"
                      viewBox="0 0 82 82"
                    >
                      <defs>
                        <filter
                          id="Rectangle_83"
                          x="0"
                          y="0"
                          width="82"
                          height="82"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.071" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        id="Component_11_1"
                        data-name="Component 11 – 1"
                        transform="translate(9 6)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Rectangle_83)"
                        >
                          <rect
                            id="Rectangle_83-2"
                            data-name="Rectangle 83"
                            width="64"
                            height="64"
                            rx="8"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Icon_material-payment"
                          data-name="Icon material-payment"
                          d="M25.823,6H5.536A2.516,2.516,0,0,0,3.013,8.536L3,23.751a2.527,2.527,0,0,0,2.536,2.536H25.823a2.527,2.527,0,0,0,2.536-2.536V8.536A2.527,2.527,0,0,0,25.823,6Zm0,17.751H5.536V16.144H25.823Zm0-12.679H5.536V8.536H25.823Z"
                          transform="translate(16.321 15.856)"
                          fill="#7ee949"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="col-lg-10">
                    <div className="more-values-content-two">
                      <h4>Transparency in Transactions</h4>
                      <p className="mt-2">
                        All transactions are above the board.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="more-values-img">
                <Fade left>
                  <img src="img/more-values-img.png" alt="more-values-img" />
                </Fade>

                <div className=" AntiqueWhite1-shape"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreValues;
