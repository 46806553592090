import React from "react";
import Layout from "../../layout";

import AboutUs from "./AboutUs";
import Culture from "./Culture";
import Mission from "./Mission";
import Partner from "./Partner";
import Vision from "./Vision";

const AboutUsMain = () => {
  return (
    <>
      <div class="about-us-main-area">
        <div class="col-lg-12">
          <div class="about-us-banner-area">
            <Layout>
              <AboutUs />
              <Vision />
              <Mission />
              <Partner />
              <Culture />
            </Layout>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsMain;
