import React from "react";
import ApparelOptimizationCard from "./ApparelOptimizationCard";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

const ApparelOptimization = () => {
  return (
    <div className="apparel-optimization-area long-section-padding ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title short-section-padding">
              <h2>Optimizing Apparel Supply Chains Digitally.</h2>
              <h5 className="mt-3">
                Select from a large collection of Fabrics to decrease your
                sourcing time to less than a week from 45 Days
              </h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6">
            <Fade left>
              <ApparelOptimizationCard
                title="Manufacturers"
                img="img/lds-icon/Group49.svg"
                text="List your unused fabrics and inventory to scores of buyers across the world"
              />
            </Fade>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6">
            <Zoom>
              <ApparelOptimizationCard
                title="Buyers"
                img="img/lds-icon/group432.svg"
                text="Access certified factories, trendy fabrics and designers globally"
              />
            </Zoom>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6">
            <Fade right>
              <ApparelOptimizationCard
                title="Designers"
                img="img/lds-icon/Group44.svg"
                text="
                Display your copyrighted designs to brands worldwide"
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApparelOptimization;
