import React from "react";
import Layout from "../../layout";

import StockFabrics from "./StockFabrics";

export default function StockFabricsMain() {
  return (
    <>
      <div class="stock-fabrics-main-area">
        <div class="col-lg-12">
          <div class="stock-fabrics-banner-area">
            <Layout>
              <div class="container">
                <StockFabrics />
              </div>
            </Layout>
          </div>
        </div>
      </div>
    </>
  );
}
