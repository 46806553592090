import React from "react";
import Zoom from "react-reveal/Zoom";

export const Partner = () => {
  return (
    <>
      <div className="partner-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="partner-area-section-title">
                <h3>TRUSTED BY 100+ COMPANIES AROUND THE WORLD</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="row">
                <div className="col-lg-3">
                  <div class="partner-content-img">
                    <Zoom>
                      <img
                        src="img/partner-image-01.png"
                        alt="partner-image-01"
                      />
                    </Zoom>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div class="partner-content-img">
                    <Zoom>
                      <img
                        src="img/partner-image-02.png"
                        alt="partner-image-02"
                      />
                    </Zoom>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div class="partner-content-img">
                    <Zoom>
                      <img
                        src="img/partner-image-03.png"
                        alt="partner-image-03"
                      />
                    </Zoom>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div class="partner-content-img">
                    <Zoom>
                      <img
                        src="img/partner-image-04.png"
                        alt="partner-image-04"
                      />
                    </Zoom>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
