import React from "react";

import Footer from "../home/Footer";
import NavbarTwo from "../home/NavbarTwo";
import List from "./List";

export default function BlogMain({ blogList }) {
  return (
    <>
      <NavbarTwo />
      <List blogList={blogList} />
      <Footer />
    </>
  );
}
