import React from "react";

const ApparelOptimizationCard = ({ title, img, text }) => {
  return (
    <>
      <div className="apparel-optimization-card-area">
        <div className="apparel-optimization-card-content">
          <img src={`${img}`} alt="apparel-optimization-card-image" />
          <h3>{title}</h3>
          <p className="mt-2">{text}</p>
        </div>
      </div>
    </>
  );
};
export default ApparelOptimizationCard;
