import React from "react";
import Fade from "react-reveal/Fade";

const ShowcaseDesign = () => {
  return (
    <>
      <div className="showcase-design-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="showcase-design-title">
                <h4>FOR DESIGNERS</h4>
                <h2 className="mb-10">Showcase & Sell your designs</h2>
                <p>
                  A virtual showroom for designers to showcase their creativity,
                  retail their designs from anywhere in the world
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="showcase-design-img">
                <Fade right>
                  <img
                    src="img/showcase-design-img.png"
                    alt="showcase-design-img"
                  />
                </Fade>

                <div className="showcase-white-shape"></div>
              </div>
            </div>

            <div className="col-lg-6">
              <div class="showcase-content-wrapper">
                <div className="showcase-design-content">
                  <div className="row">
                    <div className="col-lg-2 card-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="82"
                        height="82"
                        viewBox="0 0 82 82"
                      >
                        <defs>
                          <filter
                            id="Rectangle_100"
                            x="0"
                            y="0"
                            width="82"
                            height="82"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.071" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_4_1"
                          data-name="Component 4 – 1"
                          transform="translate(9 6)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, -9, -6)"
                            filter="url(#Rectangle_100)"
                          >
                            <rect
                              id="Rectangle_100-2"
                              data-name="Rectangle 100"
                              width="64"
                              height="64"
                              rx="8"
                              transform="translate(9 6)"
                              fill="#fff"
                            />
                          </g>
                          <path
                            id="Icon_feather-star"
                            data-name="Icon feather-star"
                            d="M18.968,3,23.9,13l11.034,1.613-7.984,7.776,1.884,10.986-9.868-5.19L9.1,33.371l1.884-10.986L3,14.609,14.034,13Z"
                            transform="translate(13.5 13.5)"
                            fill="none"
                            stroke="#3a7cee"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="col-lg-10">
                      <div className="market-faster-content-two">
                        <h4>Showcase Portfolio Globally</h4>
                        <p className="mt-2">
                          Make your designs and ideas accessible to a global
                          audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="showcase-design-content">
                  <div className="row">
                    <div className="col-lg-2 card-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="82"
                        height="82"
                        viewBox="0 0 82 82"
                      >
                        <defs>
                          <filter
                            id="Rectangle_102"
                            x="0"
                            y="0"
                            width="82"
                            height="82"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.071" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_5_1"
                          data-name="Component 5 – 1"
                          transform="translate(9 6)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, -9, -6)"
                            filter="url(#Rectangle_102)"
                          >
                            <rect
                              id="Rectangle_102-2"
                              data-name="Rectangle 102"
                              width="64"
                              height="64"
                              rx="8"
                              transform="translate(9 6)"
                              fill="#fff"
                            />
                          </g>
                          <g
                            id="Icon_feather-loader"
                            data-name="Icon feather-loader"
                            transform="translate(13 13)"
                          >
                            <path
                              id="Path_74"
                              data-name="Path 74"
                              d="M18,3V9.525"
                              transform="translate(1.312 0)"
                              fill="none"
                              stroke="#13b8f5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_75"
                              data-name="Path 75"
                              d="M18,27v6.525"
                              transform="translate(1.312 2.099)"
                              fill="none"
                              stroke="#13b8f5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_76"
                              data-name="Path 76"
                              d="M7.395,7.395l4.616,4.616"
                              transform="translate(0.384 0.384)"
                              fill="none"
                              stroke="#13b8f5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_77"
                              data-name="Path 77"
                              d="M24.36,24.36l4.616,4.616"
                              transform="translate(1.868 1.868)"
                              fill="none"
                              stroke="#13b8f5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_78"
                              data-name="Path 78"
                              d="M3,18H9.525"
                              transform="translate(0 1.312)"
                              fill="none"
                              stroke="#13b8f5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_79"
                              data-name="Path 79"
                              d="M27,18h6.525"
                              transform="translate(2.099 1.312)"
                              fill="none"
                              stroke="#13b8f5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_80"
                              data-name="Path 80"
                              d="M7.395,28.976l4.616-4.616"
                              transform="translate(0.384 1.868)"
                              fill="none"
                              stroke="#13b8f5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_81"
                              data-name="Path 81"
                              d="M24.36,12.011l4.616-4.616"
                              transform="translate(1.868 0.384)"
                              fill="none"
                              stroke="#13b8f5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </g>
                      </svg>
                      {/* <div className="icon-card">
                        <i className="fa-brands fa-stack-overflow fa-2x"></i>
                      </div> */}
                    </div>
                    <div className="col-lg-10">
                      <div className="market-faster-content-two">
                        <h4>International Clients</h4>
                        <p className="mt-2">
                          Work with International Brands from across the world.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="showcase-design-content">
                  <div className="row">
                    <div className="col-lg-2 card-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="82"
                        height="82"
                        viewBox="0 0 82 82"
                      >
                        <defs>
                          <filter
                            id="Rectangle_101"
                            x="0"
                            y="0"
                            width="82"
                            height="82"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.071" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_6_1"
                          data-name="Component 6 – 1"
                          transform="translate(9 6)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, -9, -6)"
                            filter="url(#Rectangle_101)"
                          >
                            <rect
                              id="Rectangle_101-2"
                              data-name="Rectangle 101"
                              width="64"
                              height="64"
                              rx="8"
                              transform="translate(9 6)"
                              fill="#fff"
                            />
                          </g>
                          <path
                            id="Icon_simple-graphcool"
                            data-name="Icon simple-graphcool"
                            d="M30.08,11.229c-1.653-.93-3.729.283-4.061.493l-7.133,4.046a2.788,2.788,0,1,0,1.008,1.7l7.113-4.037.045-.027c.467-.3,1.552-.738,2.059-.452.365.207.574.9.591,1.912h-.008v8.923A2.338,2.338,0,0,1,28.528,25.8L18.3,31.709a2.338,2.338,0,0,1-2.331,0L5.742,25.8a2.338,2.338,0,0,1-1.165-2.019V11.971A2.334,2.334,0,0,1,5.742,9.953L15,4.611a2.783,2.783,0,1,0-.684-1.881L4.765,8.246a4.3,4.3,0,0,0-2.158,3.724V23.778A4.3,4.3,0,0,0,4.758,27.5l10.226,5.908a4.311,4.311,0,0,0,4.3,0L29.512,27.5a4.312,4.312,0,0,0,2.151-3.723V15.2h.007c.048-2.014-.491-3.346-1.59-3.968Z"
                            transform="translate(14.393 15)"
                            fill="#de9400"
                          />
                        </g>
                      </svg>
                      {/* <div className="icon-card">
                        <i class="fa-brands fa-stack-overflow fa-2x"></i>
                      </div> */}
                    </div>
                    <div className="col-lg-10">
                      <div className="market-faster-content-two">
                        <h4>Assured Remittance</h4>
                        <p className="mt-2">
                          Safe and protected transaction process
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="showcase-design-content">
                  <div className="row">
                    <div className="col-lg-2 card-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="82"
                        height="82"
                        viewBox="0 0 82 82"
                      >
                        <defs>
                          <filter
                            id="Rectangle_103"
                            x="0"
                            y="0"
                            width="82"
                            height="82"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.071" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_7_1"
                          data-name="Component 7 – 1"
                          transform="translate(9 6)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, -9, -6)"
                            filter="url(#Rectangle_103)"
                          >
                            <rect
                              id="Rectangle_103-2"
                              data-name="Rectangle 103"
                              width="64"
                              height="64"
                              rx="8"
                              transform="translate(9 6)"
                              fill="#fff"
                            />
                          </g>
                          <g
                            id="Icon_feather-trending-down"
                            data-name="Icon feather-trending-down"
                            transform="translate(12.5 12.909)"
                          >
                            <path
                              id="Path_82"
                              data-name="Path 82"
                              d="M35.951,27.792,21.075,12.915l-7.83,7.83L1.5,9"
                              transform="translate(0)"
                              fill="none"
                              stroke="#6f28ec"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_83"
                              data-name="Path 83"
                              d="M25.5,27.4h9.4V18"
                              transform="translate(1.055 0.396)"
                              fill="none"
                              stroke="#6f28ec"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </g>
                      </svg>
                      {/* <div className="icon-card">
                        <i className="fa-brands fa-stack-overflow fa-2x"></i>
                      </div> */}
                    </div>
                    <div className="col-lg-10">
                      <div className="market-faster-content-two">
                        <h4>Flexible Revenue Opportunities</h4>
                        <p className="mt-2">
                          Fixed and Variable remuneration options
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowcaseDesign;
