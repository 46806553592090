import React from "react";

const GetInTouch = () => {
  return (
    <div className="get-in-touch-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="get-in-touch-content">
              <h1>Get In Touch</h1>
              <h6>Drop us a note...</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
