import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";

export default function StockFabricsCard() {
  const [stockfabrics, setStockfabrics] = useState("");

  const handlesweetalert = () => {
    Swal.fire({
      icon: "warning",
      html: `To view more details please login to LDSL platform`,

      showCancelButton: true,
      focusConfirm: false,
      reverseButtons: true,
      confirmButtonColor: "#141d82",
      cancelButtonColor: "#FF0000",
      focusCancel: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Log in`,
    }).then((result) => {
      if (result.value) {
        window.open("https://app.dgarma.com");
      }
    });
  };

  const fetchStockfabrics = useCallback(() => {
    if (true) {
      try {
        fetch(
          "https://prod-reader-api.london-design-studios.com/api/v1/products/public-list?page=1&limit=20"
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log("data", data);
            setStockfabrics(data.products);
          })
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchStockfabrics();
  }, []);

  return (
    <div>
      <div class="stock-fabrics-card-area">
        <div class="conteiner">
          <div class="row">
            <div class="col-lg-12">
              <div>
                <div class="row">
                  {stockfabrics.length &&
                    stockfabrics?.map((doc) => {
                      let str = doc?.breadcrumb;
                      let arr = str
                        .split("~")
                        .reverse()
                        .toString()
                        .split(",")
                        .join(" ")
                        .split(" ");

                      let nedat = arr.pop();

                      let res = arr.join(" ");

                      let pic = `${process.env.REACT_APP_ASSETS}/public/products/${doc.thumbnails}`;
                      console.log(doc.thumbnails, "thumbnails");
                      return (
                        <div class="col-lg-3">
                          <div
                            className="single-card-view"
                            key={doc?._id}
                            onClick={handlesweetalert}
                          >
                            <div className="single-img">
                              <div className="react-aspect-ratio-placeholder">
                                <img src={pic} alt="product" />
                              </div>
                              <div className="tag-action">
                                <span>{doc.breadcrumb.split("~").shift()}</span>
                              </div>
                            </div>
                            <div className="card-content-wrapper text-center">
                              <h3>Terry Cotton 100%</h3>
                            </div>
                            <div className="card-content-wrapper card-absolute-content text-center">
                              <h3 className="text-black">{res}</h3>
                              <div className="d-flex">
                                <span className="text-primary pr-5">
                                  {" "}
                                  {doc.noOfVariant ? doc.noOfVariant : 0}
                                </span>{" "}
                                {doc.noOfVariant === 1 ? (
                                  <p className="pr-35">More Fabric</p>
                                ) : (
                                  <p className="pr-35">More Fabrics</p>
                                )}
                                <p>
                                  Qty:{" "}
                                  <span className="text-primary">
                                    {" "}
                                    {doc.stockTotal.toFixed(2)}{" "}
                                    {doc.quantityUnit}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="see-more-d mb-5 mt-5">
                  <button
                    onClick={handlesweetalert}
                    className="stock-see-more-btn"
                  >
                    See More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
