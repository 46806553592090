import React from "react";
import Fade from "react-reveal/Fade";

const Vision = () => {
  return (
    <div className="vision-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div class="vision-content">
              <div className="section-title mt-40">
                <h2>Our Vision</h2>
              </div>
              <h6 className="mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="27"
                  viewBox="0 0 36 27"
                >
                  <path
                    id="Icon_open-double-quote-serif-right"
                    data-name="Icon open-double-quote-serif-right"
                    d="M0,0V13.5H9a8.969,8.969,0,0,1-9,9V27A13.54,13.54,0,0,0,13.5,13.5V0ZM22.5,0V13.5h9a8.969,8.969,0,0,1-9,9V27A13.54,13.54,0,0,0,36,13.5V0Z"
                    transform="translate(36 27) rotate(180)"
                  />
                </svg>
                &nbsp; &nbsp;To be a global leader by reinventing the apparel
                supply chain industry by digitization, optimizing resources,
                reducing wastage for a sustainable future.&nbsp; &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="27"
                  viewBox="0 0 36 27"
                >
                  <path
                    id="Icon_open-double-quote-serif-right"
                    data-name="Icon open-double-quote-serif-right"
                    d="M0,0V13.5H9a8.969,8.969,0,0,1-9,9V27A13.54,13.54,0,0,0,13.5,13.5V0ZM22.5,0V13.5h9a8.969,8.969,0,0,1-9,9V27A13.54,13.54,0,0,0,36,13.5V0Z"
                  />
                </svg>
              </h6>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="vision-img">
              <Fade left>
                <img src="img/vision-img.png" alt="vision-img" />
              </Fade>

              <div className="vision-img-shape"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
