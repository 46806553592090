import React from "react";

export default function PrivacyPolicy() {
  return (
    <>
      <div class="privacy-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="privacy-area-image">
                <img src="img/privacy.jpg" alt="privacy-image" />
              </div>
              <div class="privacy-content">
                <h4>Privacy Policy</h4>
                <p>
                  We, London Design Studios Limited, a company duly incorporated
                  under the Companies Act XVIII,1994 and having its registered
                  office at Sahabuddin Plaza (4th Floor), 01 Janata Housing,Ring
                  Road,Adabar, Mohammadpur, Dhaka 1207;Adabor PS; Dhaka-1207;
                  Bangladesh (“LDS”) (hereinafter referred to as the “Company”
                  “us”, “we” or “our”), To carry on the business of Advertising
                  and marketing for SMES, Heavy Engineering, Fabrics, Garments,
                  Creating virtual malls, stores, shops, creating Marketing
                  catalogues, Engineering Consultancy services, net
                  commerce/Engineering solutions for business to business and
                  business to consumers, online Engineering Support services
                  like Manufacturing Cost Estimation, Technical & Managerial
                  problem solving through the engagement of virtual consultants.
                  business of online shopping, marketing of consumer and other
                  goods, trading in and outside Bangladesh but does not include
                  banking and money circulating business, Online supplying of
                  engineering materials to SMEs ,Online Logistical solutions &
                  support ,Project management etc. through our Website/ App (if
                  applicable) ( “Platform”). Reference to “you” or “your” in
                  this Privacy Policy refers to the users of this Platform,
                  whether or not you access the services offered by us
                  (“Services”) or consummate a transaction on the Platform. We
                  are fully committed to respecting your privacy and shall
                  ensure that your personal information is safe with us. This
                  privacy policy sets out the information practices of our
                  Platform including the type of information that is collected,
                  how the information is collected, how the information is used
                  and with whom it is shared (“Privacy Policy”). This Privacy
                  Policy is published in accordance with the provisions of the
                  Information Technology Act, 2000 and the rules made thereunder
                  that require publishing the privacy policy and terms of use on
                  an online portal of the Company. We request you to go through
                  this Privacy Policy and the Terms of Use carefully before you
                  decide to use this Platform.
                </p>
                <p>
                  <strong>
                    BY USING OUR PLATFORM, SERVICES AND THE APP (IF APPLICABLE)
                    OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU AGREE TO THE
                    TERMS OF THIS PRIVACY POLICY. YOU ALSO EXPRESSLY CONSENT TO
                    OUR USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION (AS
                    DEFINED BELOW) IN THE MANNER PRESCRIBED UNDER THIS PRIVACY
                    POLICY AND FURTHER SIGNIFY YOUR AGREEMENT TO THIS PRIVACY
                    POLICY AND THE TERMS OF USE (AVAILABLE AT
                    https://london-design-studios/privacy-policy. IF YOU DO NOT
                    AGREE TO THIS PRIVACY POLICY, DO NOT SUBSCRIBE TO THE
                    SERVICES, USE THE PLATFORM AND DO NOT GIVE US ANY OF YOUR
                    INFORMATIONS.
                  </strong>
                </p>
                <h4>DEFINITIONS</h4>
                <p>
                  “App” shall mean the software application proposed to be
                  launched, which can be downloaded from a public forum and
                  installed and operated on any computer or handheld device.
                  “Force Majeure Event” shall mean any event that is beyond our
                  reasonable control and shall include, without limitation,
                  sabotage, fire, flood, explosion, acts of God, civil
                  commotion, strikes or industrial action or any kind, riots,
                  insurrection, war, acts of government, computer hacking,
                  unauthorized access to your mobile or storage device, crashes,
                  breach of security and encryption. “Platform” shall mean the
                  App (if applicable) and the Website, as the context may so
                  require.
                  <br></br>
                  <br></br>
                  “Sensitive Personal Data or Information” consists of
                  information relating to the following:
                </p>
                <ol>
                  <li>passwords;</li>
                  <li>
                    financial information such as bank account or credit card or
                    debit card or other payment instrument details;
                  </li>
                  <li>sexual orientation;;</li>
                  <li>biometric information;;</li>
                  <li>
                    any detail relating to the above clauses as provided to us
                    for providing service; and ;
                  </li>
                  <li>passwords;</li>
                </ol>
                <p>
                  Provided that, any information that is freely available or
                  accessible in the public domain or furnished under the right
                  to information act, 2005 or any other law for the time being
                  in force shall not be regarded as sensitive personal data or
                  information. “third party(ies)” refers to any person or entity
                  other than you or us. “website” shall mean
                  https://london-design-studios.com
                </p>
                <h4>COLLECTION OF INFORMATION</h4>
                <p>
                  We are the sole owner of the information collected through the
                  platform. We may collect and process sensitive personal data
                  or information provided by you in the following forms:
                </p>
                <ol>
                  <li>
                    Information provided by you at the time of registration such
                    as your name, age, address, email id, and phone number.
                    Providing additional information beyond what is required at
                    the time of registration is entirely optional and can be
                    altered or removed by you at any time;
                  </li>
                  <li>
                    Information that you provide directly to us via email or
                    electronic communication;
                  </li>
                  <li>
                    Information that you provide to us over the telephone. We
                    may make and keep a record of such information shared by
                    you; and
                  </li>
                  <li>
                    Information that you provide to us in the physical form
                    whether sent through post or courier or handed over to our
                    representatives in person.
                  </li>
                </ol>
                <p>
                  Every computer/mobile device connected to the internet is
                  given a domain name and a set of numbers that serve as that
                  computer's Internet Protocol or “IP” address. When you request
                  a page from any page within the Platform, our web servers
                  automatically recognize your domain name and IP address. The
                  domain name and IP address reveal nothing personal about you
                  other than the IP address from which you have accessed the
                  Platform.
                </p>
                <h4>USE OF INFORMATION COLLECTED</h4>
                <p>
                  Our primary goal in collecting your Sensitive Personal Data or
                  Information is to customize the content you see on our
                  Platform, to fulfill your requests for certain Services, and
                  contact you in the future to discuss our Services. Cookies:
                  Cookies are small pieces of information saved by your browser.
                  Cookies are used to record various aspects of your visit and
                  assist us to provide you with uninterrupted service. Cookies
                  may be set in your browser by us when you access the Platform
                  or may be set in when you visit Third Party apps or websites.
                  This anonymous information is maintained distinctly and is not
                  linked to the Sensitive Personal Data or Information that you
                  submit to us. We use cookies collected to:
                </p>
                <ol>
                  <li>Authenticate your login information;</li>
                  <li>Enable our security features;</li>
                  <li>Show you advertising; and</li>
                  <li>Improve and develop the features of the Platform.</li>
                </ol>
                <p>
                  Please change your browser settings if you do not wish to
                  accept cookies. However, please note that changing your
                  browser setting may affect your experience on the platform.
                  Communication: We may offer email, app notifications (if
                  applicable), short message service (SMS), multimedia message
                  service (MMS), or other forms of communication to respond to
                  any queries that you may have, to provide any information in
                  connection with the relevant services availed by you and to
                  ensure that such services have been provided to you in a
                  timely manner. You may receive such communication when you
                  have registered as a user.
                </p>
                <h4>SHARING OF INFORMATION</h4>
                <p>
                  To the extent necessary to provide you the Services, we may
                  provide your Sensitive Personal Data or Information to the
                  following Third Parties without notice to you:
                </p>
                <ol>
                  <li>
                    Supply partners, who work on behalf of or with us to provide
                    you with such services, to help us communicate with you or
                    to maintain the Platform, on a 'need to know basis.
                  </li>
                  <li>
                    Governmental authorities, in such manner, as permitted or
                    required by applicable law; and
                  </li>
                  <li>
                    Another business entity, should we plan to merge with or be
                    acquired by that business entity, or organization,
                    amalgamation, restructuring of business. Should such a
                    transaction occur, that other business entity (or the new
                    combined entity) will be required to follow this Privacy
                    Policy with respect to your Sensitive Personal Data or
                    Information
                  </li>
                </ol>
                <p>
                  Legal proceedings: In the event, we are required to respond to
                  subpoenas, court orders or other legal process, your Sensitive
                  Personal Data or Information may be disclosed pursuant to such
                  subpoena, court order or legal process, which may be without
                  notice to you.
                </p>
                <h4>LINK TO THIRD-PARTY WEBSITES AND APPS</h4>
                <p>
                  The Website, the App (if applicable) and any other
                  communication sent to you may also contain/display Third Party
                  advertisements and links to other websites, applications or
                  products and services. You agree and acknowledge that these
                  websites and applications are operated by Third Parties and
                  are not controlled by, or affiliated to, or associated with
                  us, unless expressly specified. We are not responsible for any
                  form of transmission, whatsoever, received by you from any
                  such Third Party. Accordingly, we do not make any
                  representations concerning the privacy practices or policies
                  of such Third Parties or terms of use of such websites or
                  applications, nor do we control or guarantee the accuracy,
                  integrity, or quality of, without limitation, the information,
                  data, text, software, music, sound, photographs, graphics,
                  videos, messages or other materials available on such websites
                  or applications. The inclusion or exclusion does not imply any
                  endorsement by us of such websites, applications, the Third
                  Party, or the information on such websites or such
                  applications. The information provided by you to such Third
                  Party websites or applications shall be governed in accordance
                  with the privacy policies of such websites or applications and
                  it is recommended that you review the privacy policy on any
                  such websites and applications prior to using such websites or
                  applications These Third-Party websites and applications and
                  advertisers may use technology to send advertisements directly
                  to your browser. In such cases, the Third-Party websites,
                  applications or advertisers automatically receive your IP
                  address. They may also use cookies, JavaScript, web beacons
                  and other technologies to measure the effectiveness of their
                  advertisements and to personalize advertising content. We do
                  not have access to or control over cookies or other features
                  that such Third Party websites and applications and
                  advertisers may use, and the information practices of these
                  Third Party websites and applications and advertisers are not
                  covered by this Privacy Policy. Please contact them directly
                  for more information about their privacy practices.
                </p>
                <h4>SECURITY OF INFORMATION</h4>
                <p>
                  We strive to ensure the security, integrity and privacy of
                  your Sensitive Personal Data or Information and to protect
                  your Sensitive Personal Data or Information against
                  unauthorized access, alteration, disclosure or destruction.
                  The Platform has stringent security measures (physical,
                  electronic and managerial) in place to protect against the
                  loss, misuse, and alteration of the Sensitive Personal Data or
                  Information under our control. You shall have a right to view
                  and modify the contents of your profile on the Platform.
                  Whenever you change or access your account information, we
                  offer the use of a secure server. The Platform servers are
                  accessible only to authorized personnel and your information
                  is shared with our employees and authorized personnel on a
                  'need to know' basis, to complete the transaction and to
                  provide the Services. We are not responsible for any breach of
                  security or for any actions of any Third Parties that receive
                  your Sensitive Personal Data or Information. The Services are
                  also linked to Third Party sites and we are not/shall not be
                  responsible for the privacy practices or the content of such
                  sites. Notwithstanding anything contained in this Privacy
                  Policy or elsewhere, we shall not be held responsible for any
                  loss, damage or misuse of your Sensitive Personal Data or
                  Information, if such loss, damage, or misuse is attributable
                  to a Force Majeure Event.
                </p>
                <h4>DATA RETENTION</h4>
                <p>
                  Your information will be retained with the Company as long as
                  your account is active. If you wish to cancel your account or
                  request that the Company no longer uses your information, you
                  may contact the Company at 09609-006240 or send an email to
                  info@london-design-studios.com We will retain and use your
                  information as necessary to comply with our legal obligations,
                  resolve disputes, and enforce our agreements or for other
                  business purposes.
                </p>
                <h4>MINOR’S PRIVACY</h4>
                <p>
                  The platform is not intended for use by minors and we strongly
                  encourage you and your representatives to supervise the online
                  activities of your and your representatives’ minor children.
                </p>
                <h4>NOTIFICATION OF CHANGES</h4>
                <p>
                  We may update this Privacy Policy at any time, with or without
                  advance notice. In the event, there are significant changes in
                  the way we treat your Personal Information, or in the Privacy
                  Policy document itself, we will display a notice on the
                  Platform so that you may review the changed terms prior to
                  continuing to use the Platform. As always, if you object to
                  any of the changes to our terms, and you no longer wish to use
                  the Platform, you may contact us at
                  contact@london-design-studios.com, to deactivate your account.
                  Unless stated otherwise, our current Privacy Policy applies to
                  all information that we have about you and your account. Using
                  our Services or accessing the Platform after a notice of
                  changes has been sent to you shall constitute your consent to
                  the changed terms.
                </p>
                <h4>ADDRESS FOR PRIVACY QUESTIONS</h4>
                <p>
                  Should you have questions about the Privacy Policy or our
                  information collection, use, and disclosure practices, you may
                  contact us at the address: Sahabuddin Plaza (4th Floor), 01
                  Janata Housing, Ring Road, Adabar, Mohammadpur, Dhaka 1207;
                  Adabor PS; Dhaka-1207; Bangladesh We will use reasonable
                  efforts to respond promptly to requests, questions, or
                  concerns you may have regarding our use of your Personal
                  Information. Except where required by law, we cannot ensure a
                  response to questions or comments regarding topics unrelated
                  to this Privacy Policy or our privacy practices.
                </p>
                <h4>GRIEVANCE OFFICER</h4>
                <p>
                  In accordance with the Information Technology Act the Rules
                  made thereunder, the name and contact details of the Grievance
                  Officer are provided below:
                </p>
                <ul>
                  <li>
                    Name:
                    <a href="mailto:anand.sparkit@gmail.com">
                      Abdullah Al Saleh Anand
                    </a>
                  </li>
                  <li>Co-Founder</li>
                  <li>Contact Number: +8801761914891</li>
                  <li>Email Id: anand@solutionspin.com</li>
                </ul>
                <h4 className="mt-5">Policy for DND</h4>
                <p>
                  By using the website and/or registering yourself at
                  london-design-studios.com you authorize us to contact you via
                  email or phone call or SMS and offer you our services,
                  imparting product knowledge, for which reasons, personally
                  identifiable information may be collected. And Irrespective of
                  the fact if also you have registered yourself under DND or DNC
                  or NCPR service, you still authorize us to give you a call
                  from the LDS for the above-mentioned purposes till 365 days of
                  your registration with us. This Privacy Policy covers LDS
                  treatment of personally identifiable information that LDS
                  collects when you are on the LDS site, and when you use LDS
                  services. This policy also covers LDS treatment of any
                  personally identifiable information that LDS business partners
                  share with LDS. This policy does not apply to the practices of
                  companies that LDS does not own or control or to people that
                  LDS does not employ or manage. LDS collects personally
                  identifiable information when you register for a LDS account,
                  when you use certain LDS products or services and when you
                  visit LDS pages. LDS may also receive personally identifiable
                  information from our business partners. When you register with
                  LDS, we ask for your first name, last name, contact no. and
                  email. Once you register with LDS and sign in to our services,
                  you are not anonymous to us. Also, during registration, you
                  may be requested to register your mobile phone and email id,
                  pager, or another device to receive text messages,
                  notifications, and other services to your wireless device. By
                  registration, you authorize us to send SMS/email alerts to you
                  for your login details and any other service and product
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
