import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";

export default function TechPackCard() {
  const [tackpackstotal, setTackpackstotal] = useState("");

  const handlesweetalert = () => {
    Swal.fire({
      icon: "warning",
      html: `To view more details please login to LDSL platform`,

      showCancelButton: true,
      focusConfirm: false,
      reverseButtons: true,
      confirmButtonColor: "#141d82",
      cancelButtonColor: "#FF0000",
      focusCancel: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Log in`,
    }).then((result) => {
      if (result.value) {
        window.open("https://app.dgarma.com");
      }
    });
  };

  const fetchTackpacks = useCallback(() => {
    if (true) {
      try {
        fetch(
          "https://prod-reader-api.london-design-studios.com/api/v1/techpacks/public-list?page=1&limit=20"
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setTackpackstotal(data.techpacks);
          })
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchTackpacks();
  }, []);
  return (
    <div>
      <div class="stock-fabrics-card-area">
        <div class="conteiner">
          <div class="row">
            {tackpackstotal.length ? (
              tackpackstotal.map((doc, index) => {
                console.log(doc.referenceImages, "tttt");

                let pic = `${process.env.REACT_APP_ASSETS}/public/techpacks/${doc.referenceImages}`;
                return (
                  <div class="col-lg-3">
                    <div
                      onClick={handlesweetalert}
                      className="single-card-view"
                      key={index}
                    >
                      <div className="single-img">
                        <a href="#">
                          <div className="react-aspect-ratio-placeholder">
                            <img src={pic} alt="product" />
                          </div>
                        </a>
                        <div className="tag-action">
                          <span> {doc.styleNo}</span>
                        </div>
                      </div>
                      <div className="card-content-wrapper text-center">
                        <h3>
                          <a href="#">Terry Cotton 100%</a>
                        </h3>
                      </div>
                      <div className="card-content-wrapper card-absolute-content text-center">
                        <h3>
                          <a href="#">{doc.name}</a>
                        </h3>
                        <div className="d-flex">
                          <p className="pr-35">
                            For:{" "}
                            <span className="text-primary">
                              {doc.measurementFor}
                            </span>
                          </p>
                          <p>
                            Type:{" "}
                            <span className="text-primary">
                              {doc.immediateGarmentCategoryName}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <h2 className="text-black">Techpacks not available</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
