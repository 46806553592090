import React from "react";
import Footer from "../home/Footer";
import NavbarTwo from "../home/NavbarTwo";
import TermsAndConditions from "./TermsAndConditions";

export default function TermsAndConditionsMain() {
  return (
    <>
      <NavbarTwo />
      <TermsAndConditions />
      <Footer />
    </>
  );
}
