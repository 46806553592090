import React from "react";
import { Link } from "react-router-dom";
import Bounce from "react-reveal/Bounce";

const JoinLDS = () => {
  return (
    <>
      <div className="joinlds-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="joinlds-content">
                <Bounce top>
                  <h2>Join our LDS family</h2>

                  <Link to="/career">
                    <button>
                      Get Started
                      <i className="fas fa-arrow-right"></i>
                    </button>
                  </Link>
                </Bounce>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default JoinLDS;
