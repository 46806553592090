import React from "react";

export default function RefundPolicy() {
  return (
    <div class="privacy-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="privacy-area-image">
              <img src="img/refund.jpg" alt="privacy-img" />
            </div>
            <div class="privacy-content">
              <h4>Refund Policy</h4>
              <p>
                LDS Platform provides its customers with only strictly
                quality-controlled goods without exemption according to 'LDS
                Platform quality standard. We will follow the specifications as
                instructed by the customer at the time of order. Any changes
                during the production will be notified and confirmed by the
                customer. We DO NOT accept any returns or refunds for any
                customized goods at all once the order has been placed or
                shipped. So you can ask for pictures, video conference or
                anything that would be possible to show you your followed
                specifications. You can make any changes to the garment as long
                as the sample has not been approved for bulk production or
                shipped out. We can also make changes after the order has been
                shipped, but you will have to pay for the shipment and
                re-shipment of the garment.For any failed delivery you will
                receive refund within 7-10 working days to your original payment
                source.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
