import React from "react";
import TechPackCard from "./TechPackCard";

export default function TechPackHome() {
  return (
    <div>
      <div class="tech-pack-home-area">
        <div class="container">
          <div class="row ">
            <div class="col-lg-12">
              <TechPackCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
