import React from "react";
import Layout from "../../layout";

import TechPackHome from "./TechPackHome";

export default function index() {
  return (
    <>
      <div class="tech-pack-main-area">
        <div class="col-lg-12">
          <div class="tech-pack-banner-area">
            <Layout>
              <div className="container">
                <TechPackHome />
              </div>
            </Layout>
          </div>
        </div>
      </div>
    </>
  );
}
