import React, { useCallback, useEffect, useState } from "react";

import { Route, Routes } from "react-router-dom";
import "./App.css";
import AboutUsMain from "./pages/AboutUs/AboutUsMain";
import CareerMain from "./pages/Career/CareerMain";
import ContactUsMain from "./pages/ContactUs/ContactUsMain";
import HomeMain from "./pages/home/HomeMain";
import PolicyMain from "./pages/Policy/PolicyMain";
import StockFabricsMain from "./pages/StockFabrics/StockFabricsMain";
import TermsAndConditionsMain from "./pages/TermsAndConditions/TermsAndConditionsMain";
import Techpack from "./pages/Techpack";
import RefundPolicyMain from "./pages/RefundPolicy.js/RefundPolicyMain";
import Blog from "./pages/Blog";
import BlogDeatails from "./pages/Blog/Details";

function App() {
  const [blogList, setblogList] = useState([]);

  const fetchBlogList = useCallback(() => {
    if (true) {
      try {
        fetch(
          "https://prod-reader-api.london-design-studios.com/api/v1/blogs/list?page=1&limit=20"
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setblogList(data.blogs);
          })
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchBlogList();
  }, [fetchBlogList]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/contactus" element={<ContactUsMain />} />
        <Route path="/aboutus" element={<AboutUsMain />} />
        <Route path="/policy" element={<PolicyMain />} />
        <Route path="/career" element={<CareerMain />} />
        <Route path="/terms-conditions" element={<TermsAndConditionsMain />} />
        <Route path="/refund-policy" element={<RefundPolicyMain />} />

        <Route path="/stock-fabrics" element={<StockFabricsMain />} />
        <Route path="/tech-pack" element={<Techpack />} />
        <Route path="/blog" element={<Blog blogList={blogList} />} />
        <Route
          path="/blog-details/:id"
          element={<BlogDeatails blogList={blogList} />}
        />
      </Routes>
    </div>
  );
}

export default App;
