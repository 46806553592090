import React from "react";
import ApparelOptimization from "./ApparelOptimization";
import AugmentResources from "./AugmentResources";
import Contact from "./Contact";
import CustomerReviews from "./CustomerReviews";
import Footer from "./Footer";
import JoinLDS from "./JoinLDS";
import MarketFaster from "./MarketFaster";
import MoreValues from "./MoreValues";
import { Partner } from "./Partner";
import ShowcaseDesign from "./ShowcaseDesign";
import TopMenuBar from "./TopMenuBar";

const HomeMain = () => {
  return (
    <>
      <TopMenuBar />
      <ApparelOptimization />
      <AugmentResources />
      <MarketFaster />
      <MoreValues />
      <ShowcaseDesign />
      {/* <CustomerReviews /> */}
      <Partner />
      <JoinLDS />

      {/* <FAQ /> */}
      <Contact />
      <Footer />
    </>
  );
};
export default HomeMain;
