import React from "react";
import Zoom from "react-reveal/Zoom";

export default function Partner() {
  return (
    <div className="aboutus-partner-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Zoom>
              <div className="partner-world-map-img">
                <img src="img/world-map.png" alt="world-map-img" />
              </div>
            </Zoom>
          </div>

          <div className="col-lg-6">
            <div className="partner-content-one">
              <h3 className="pb-25">OUR TRUSTED FACTORY PARTNERS FROM</h3>
              <ul>
                <img src="img/bd-flag.png" alt="world-map-img" />

                <li>Bangladesh</li>
                <img src="img/india-flag.png" alt="world-map-img" />

                <li>India</li>
                <img src="img/china-flag.png" alt="world-map-img" />

                <li>China</li>
              </ul>
            </div>
            <div className="partner-content-two">
              <h3 className="mt-30 pb-25">SUPPLY TO VERIFIED BUYERS ACROSS</h3>
              <ol>
                <li>Europe</li>
                <li>North America</li>
                <li>India</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
