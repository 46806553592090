import React, { useState } from "react";
import { Link } from "react-router-dom";
import Bounce from "react-reveal/Bounce";
const NavbarTwo = () => {
  const [show, setShow] = useState("false");

  const raiseInvoiceClicked = () => {
    // your axios call here
    localStorage.setItem("pageData", "Data Retrieved from axios request");
    // route to new page by changing window.location
    window.open("https://app.dgarma.com", "_blank"); //to open new page
  };
  const handleClick = () => {
    if (show === "true") {
      setShow("false");
    }

    if (show === "false") {
      setShow("true");
    }
  };

  return (
    <>
      <div className="navbar-area ">
        <div className="container">
          <div className="row align-items-center">
            <div class="mobile-btn bblack-color text-right ">
              <button onClick={handleClick}>
                <i class="fas fa-bars footer-area-lnk-clr"></i>
              </button>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="top-menu-bar-logo-two">
                <Link to="/">
                  <img
                    src={process.env.PUBLIC_URL + "/img/logo.png"}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-9 col-md-9 destop-menu ">
              <div className="top-menu-bar-content-two">
                <ul>
                  <Link className="footer-area-lnk-clr" to="/stock-fabrics">
                    <li>Stocks</li>
                  </Link>
                  <Link className="footer-area-lnk-clr" to="/tech-pack">
                    <li>Tech Packs</li>
                  </Link>
                  <Link className="footer-area-lnk-clr" to="/aboutus">
                    <li> About Us </li>
                  </Link>
                  <Link className="footer-area-lnk-clr" to="/career">
                    <li> Career</li>
                  </Link>

                  <Link className="footer-area-lnk-clr" to="/contactus">
                    <li>Contact Us </li>
                  </Link>
                  <Link className="footer-area-lnk-clr" to="/blog">
                    <li>Blog</li>
                  </Link>

                  <li onClick={raiseInvoiceClicked}>Log in</li>

                  <li className="navbar-explore-btn scroll-login">
                    <a
                      className="text-light"
                      href="https://dgarma.com"
                      target="_blank"
                    >
                      Connect DGarma
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {show === "true" ? (
              <Bounce top>
                <div>
                  <div class="mobile-navbar-area">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="mobile-navbar">
                          <div class="mobile-btn text-right">
                            <button onClick={handleClick}>
                              <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div class="mobile-navbar-content">
                            <ul>
                              <li>
                                <Link
                                  className="footer-area-lnk-clr"
                                  to="/aboutus"
                                >
                                  ABOUT US
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="footer-area-lnk-clr"
                                  to="/career"
                                >
                                  CAREER
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="footer-area-lnk-clr"
                                  to="/contactus"
                                >
                                  CONTACT US
                                </Link>
                              </li>

                              <Link className="footer-area-lnk-clr" to="/blog">
                                <li>Blog</li>
                              </Link>

                              <li onClick={raiseInvoiceClicked}>
                                SIGN UP / LOG IN
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Bounce>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default NavbarTwo;
