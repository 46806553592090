import React from "react";
import StockFabricsCard from "./StockFabricsCard";

export default function StockFabrics() {
  return (
    <>
      <div class="stock-fabrics-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <StockFabricsCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
