import React from "react";

const ContactCard = ({ title, subtitle, secondarytext, icon }) => {
  return (
    <>
      <div className="contact-card-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-card-content">
                <img src={icon} alt="customer-review-img" />
                <div className="contact-card-title">
                  <h4>{title}</h4>
                  <p>{subtitle}</p>
                  <p>{secondarytext}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
