import React from "react";
import GetInTouch from "./GetInTouch";
import SendAMessage from "./SendAMessage";
import Layout from "../../layout";

const ContactUsMain = () => {
  return (
    <>
      <div class="contactus-main-area">
        <div class="col-lg-12">
          <Layout>
            <GetInTouch />
            <SendAMessage />
          </Layout>
        </div>
      </div>
    </>
  );
};

export default ContactUsMain;
