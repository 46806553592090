import React from "react";
import ContactCard from "./ContactCard.js";
import Fade from "react-reveal/Fade";

import Zoom from "react-reveal/Zoom";

const Contact = () => {
  return (
    <div className="contact-area short-section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="contact-title">
              <h2 className="m-10">Still have a Question</h2>
              <p className="mb-10">
                If you cannot find an answer to your question in our FAQ, you
                can always contact us.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <Fade left>
              <ContactCard
                icon="img/lds-icon/contact-address-icon.svg"
                title="Address"
                subtitle="13-23, Road-1, Janata Co. Operative Housing Society (5th Floor), Ring Road, Adabor, Dhaka-1207"
              />
            </Fade>
          </div>
          <div className="col-lg-4">
            <Zoom>
              <ContactCard
                icon="img/lds-icon/contact-message-icon.svg"
                title="Support Email"
                subtitle="technology@london-design-studios.com"
              />
            </Zoom>
          </div>
          <div className="col-lg-4">
            <Fade right>
              <ContactCard
                icon="img/lds-icon/contact-phone-icon.svg"
                title="Contact"
                subtitle="+880 09609-006240"
                secondarytext="Sunday - Thursday, 10AM - 6PM BST"
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
