import React from "react";
import Footer from "../home/Footer";
import NavbarTwo from "../home/NavbarTwo";
import RefundPolicy from "./RefundPolicy";

export default function RefundPolicyMain() {
  return (
    <>
      <NavbarTwo />
      <RefundPolicy />
      <Footer />
    </>
  );
}
