import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";

export default function List({ blogList }) {
  return (
    <>
      <div class="blog-area p-3">
        <div class="container">
          <div class="row">
            {blogList?.map((item, index) => {
              let picPath = `https://dgarma.s3.ap-southeast-1.amazonaws.com/public/blogs/${item?.thumbnail}`;
              return (
                <div key={index} class="col-lg-3 mb-3">
                  <Link to={`/blog-details/${item._id}`}>
                    <div class="blog-card">
                      <div class="blog-card-img">
                        <img src={picPath} alt="blog" />
                      </div>
                      <div class="blog-content p-3">
                        <h6>{item?.title}</h6>
                        <p className="mt-2">{item?.shortDescription}</p>

                        <p className="mt-2">
                          <b>{moment(item?.createdAt).format("LL")}</b>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
