import React from "react";
import Career from "./Career";
import Layout from "../../layout";

export default function CareerMain() {
  return (
    <>
      <div class="career-main-area">
        <div class="col-lg-12">
          <Layout>
            <Career />
          </Layout>
        </div>
      </div>
    </>
  );
}
