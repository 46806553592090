import React from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
const AboutUs = () => {
  return (
    <div className="about-us-area long-section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-white">
              <h2>About Us</h2>
              <h6 className="short-section-padding mb-5">
                LDS aims to boost development and bring order to an otherwise
                chaotic and unorganized segment of unused but trendy fabrics, by
                creating a digital ecosystem of apparel manufacturers, buyers,
                and designers. We hope to transform the industry by digitizing
                and democratizing the various systems and processes. Our
                Leadership team has over 100 plus man-years of professional
                experience in various facets of Fashion, Technology and
                Operations.
              </h6>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="about-us-image">
              <Zoom>
                <img src="img/about-us-one.png" alt="about-us-image" />
              </Zoom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
