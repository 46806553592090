import React from "react";
import Footer from "../home/Footer";
import NavbarTwo from "../home/NavbarTwo";
import PrivacyPolicy from "./PrivacyPolicy";

const PolicyMain = () => {
  return (
    <>
      <NavbarTwo />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PolicyMain;
