import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="footer-area">
        <div className="row">
          <div className="col-lg-2">
            <div class="footer-logo">
              <Link to="/">
                <svg
                  id="Component_16_1"
                  data-name="Component 16 – 1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="218.1"
                  height="96.771"
                  viewBox="0 0 218.1 96.771"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_49"
                        data-name="Rectangle 49"
                        width="44.019"
                        height="51.946"
                        fill="none"
                      />
                    </clipPath>
                    <clipPath id="clip-path-2">
                      <rect
                        id="Rectangle_62"
                        data-name="Rectangle 62"
                        width="218.1"
                        height="28.901"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Group_65"
                    data-name="Group 65"
                    transform="translate(86.526)"
                  >
                    <g
                      id="Group_33"
                      data-name="Group 33"
                      transform="translate(0 0)"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="Path_14"
                        data-name="Path 14"
                        d="M22.763,51.444l-.754.5-.754-.5C12.444,45.576-.3,37.475.005,25.557V0H44.013V25.541c.3,11.934-12.439,20.036-21.25,25.9"
                        transform="translate(0 0)"
                        fill="#0b0d22"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_15"
                        data-name="Path 15"
                        d="M2.164,2.158V26.1C1.882,37.144,14.3,45.014,22.521,50.481h0C30.745,45.014,43.16,37.144,42.879,26.1V2.158Z"
                        transform="translate(-0.512 -0.512)"
                        fill="#ff1901"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M1.655,1.7V15.925h41.1V1.7H1.655Z"
                        transform="translate(-0.393 -0.404)"
                        fill="#0b0d22"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_17"
                        data-name="Path 17"
                        d="M33.987,34.616v2.558a2.8,2.8,0,0,1-.574,1.772h.574v4.7a2.706,2.706,0,0,1-2.728,2.8H21.226v-4.7a2.8,2.8,0,0,1,.575-1.773h-.576V34.615h1.147a3.637,3.637,0,0,1-1.09-2.733v-5.3h3.166v5.3c0,.15.011.354.151.443a.485.485,0,0,0,.163.063,1.128,1.128,0,0,0,.113.019l.046,0h9.067Z"
                        transform="translate(-5.035 -6.305)"
                        fill="#0b0d22"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M33.369,33.586H25.2a1.455,1.455,0,0,1-.149-.008c-.069-.008-.136-.02-.2-.034a1.4,1.4,0,0,1-.455-.184,1.316,1.316,0,0,1-.565-1.2V27.754H22.458v4.407A2.711,2.711,0,0,0,25.2,35.013h8.17ZM22.4,43.693H32v.261l0,.029a.513.513,0,0,1,0,.069.506.506,0,0,1-.05.155.453.453,0,0,1-.413.2H22.4v1.428h9.137a1.812,1.812,0,0,0,1.831-1.905V42.265h-9.6v-.293a.281.281,0,0,1,.008-.069.477.477,0,0,1,.051-.156.445.445,0,0,1,.408-.195h9.128V40.123H24.232A1.813,1.813,0,0,0,22.4,42.029Zm0-6.472H32v.26l0,.03a.516.516,0,0,1,0,.069.487.487,0,0,1-.051.155.451.451,0,0,1-.412.2H22.4v1.428h9.137a1.812,1.812,0,0,0,1.831-1.905V35.792h-9.6v0H22.4Z"
                        transform="translate(-5.314 -6.584)"
                        fill="#fff"
                        fill-rule="evenodd"
                      />
                      <rect
                        id="Rectangle_47"
                        data-name="Rectangle 47"
                        width="24.888"
                        height="7.871"
                        transform="translate(9.427 4.653)"
                        fill="#fff"
                      />
                      <path
                        id="Path_19"
                        data-name="Path 19"
                        d="M19.256,13.448c-.069,0-.054-.008-.054.027.069,0,.054.008.054-.027"
                        transform="translate(-4.554 -3.19)"
                        fill="#fa675b"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M43.569,15.988c0-.036.016-.027-.053-.027,0,.034-.015.024.053.027"
                        transform="translate(-10.322 -3.786)"
                        fill="#fa675b"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_21"
                        data-name="Path 21"
                        d="M36.693,13.779c0-.038.015-.026-.066-.025,0,.036-.014.023.066.025"
                        transform="translate(-8.688 -3.262)"
                        fill="#f8546f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_22"
                        data-name="Path 22"
                        d="M44.616,16.32c0-.037.014-.025-.067-.026,0,.037-.014.024.067.026"
                        transform="translate(-10.567 -3.865)"
                        fill="#f8546f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_23"
                        data-name="Path 23"
                        d="M38.245,9c-.074,0-.058-.011-.058.027.073,0,.058.009.058-.027"
                        transform="translate(-9.058 -2.134)"
                        fill="#f8546f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_24"
                        data-name="Path 24"
                        d="M21.9,9.146c0-.034.015-.024-.052-.027,0,.036-.017.027.052.027"
                        transform="translate(-5.183 -2.163)"
                        fill="#f8546f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_25"
                        data-name="Path 25"
                        d="M19.263,8.3l-.049-.026c0,.034-.016.025.049.026"
                        transform="translate(-4.557 -1.963)"
                        fill="#f8546f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M41.031,8.117l-.063.042Z"
                        transform="translate(-9.718 -1.926)"
                        fill="#f8546f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_27"
                        data-name="Path 27"
                        d="M22.235,13.257l-.079.041Z"
                        transform="translate(-5.256 -3.145)"
                        fill="#f8546f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M41.966,15.474l-.045-.026Z"
                        transform="translate(-9.944 -3.665)"
                        fill="#f8546f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M30.333,11.8c.921-.011,10.729.036,11.189-.014l.014-1.422c-.019-.139.012-.051-.066-.095l.012,1.5-2.79.007c-2.574,0-5.507.024-8.049,0-.3,0-.26-.018-.311.026"
                        transform="translate(-7.195 -2.436)"
                        fill="#fa293a"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_30"
                        data-name="Path 30"
                        d="M23.53,11.8a2.359,2.359,0,0,0-.658-.027c-1.6,0-9.967.028-10.489-.008l.013-1.5c-.072.026-.069-.031-.063.166,0,.062,0,.124,0,.185a10.155,10.155,0,0,0,.014,1.167Z"
                        transform="translate(-2.923 -2.436)"
                        fill="#fa293a"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M31.49,8.424c.188-.009,7.366-2.313,7.4-2.341l-7.437,0c-.065.152.041,2.008.038,2.343"
                        transform="translate(-7.455 -1.443)"
                        fill="#111384"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_32"
                        data-name="Path 32"
                        d="M43.358,14.655l0-1.5c0-.2-.037-.148-.463-.148-.276,0-.555,0-.831,0l-3.987-.013c.139.075,2.252.725,2.616.841l1.972.622a3.571,3.571,0,0,0,.7.2"
                        transform="translate(-9.031 -3.082)"
                        fill="#111384"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_33"
                        data-name="Path 33"
                        d="M12.345,14.657c.172-.012,2.308-.706,2.642-.812.247-.079,2.5-.772,2.642-.846l-5.222.019c-.14.04-.057.407-.057.51,0,.37.024.762,0,1.129"
                        transform="translate(-2.924 -3.084)"
                        fill="#111384"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M14.222,9.006l3.408,0c-.12-.073-5.007-1.613-5.161-1.635-.134-.046-.031-.018-.072.012l0,1.591Z"
                        transform="translate(-2.94 -1.744)"
                        fill="#111384"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M42.693,9c.012,0,.026-.006.035-.007l.3-.012c.1,0,.2,0,.307,0l-.008-1.644c-1.141.384-2.7.859-3.938,1.246L38.086,9Z"
                        transform="translate(-9.034 -1.739)"
                        fill="#111384"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_36"
                        data-name="Path 36"
                        d="M23.534,8.434l.073-2.356-7.459,0c.113.063,7.133,2.311,7.386,2.354"
                        transform="translate(-3.83 -1.442)"
                        fill="#111384"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M31.428,15.708l7.472,0c-2.275-.765-4.619-1.473-6.935-2.217a1.731,1.731,0,0,0-.474-.128Z"
                        transform="translate(-7.455 -3.17)"
                        fill="#111384"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M23.544,13.367c-.167.007-7.321,2.3-7.384,2.344l7.449,0Z"
                        transform="translate(-3.833 -3.171)"
                        fill="#111384"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M12.415,9.274l-.013,1.5c.522.036,8.885.008,10.489.008a2.359,2.359,0,0,1,.658.027l-.007,3.178H26.08V10.8c.051-.043.013-.029.311-.026,2.542.024,5.475,0,8.048,0l2.791-.007-.012-1.5-11.112,0-.019-3.192H23.565c-.144.3.075,2.713-.037,3.188Z"
                        transform="translate(-2.942 -1.442)"
                        fill="#ff1901"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M17.632,13.343c0,.035.015.025-.054.027-.623.172-1.913.6-2.628.822l-2.594.822v.59c.183-.009,5.511-1.751,6.54-2.076.261-.083.68-.237.936-.291l.079-.04a1.826,1.826,0,0,0,.263-.08l.267-.1c-.309-.007-1.469-.024-1.712,0s-.826.267-1.1.324"
                        transform="translate(-2.931 -3.085)"
                        fill="#ff1901"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_41"
                        data-name="Path 41"
                        d="M19.652,8.432c-.069,0-.052.008-.052-.027-.292-.07-.69-.216-.981-.31-.182-.059-.308-.1-.49-.156-.153-.046-.348-.122-.493-.153-.065,0-.049.008-.049-.027-.219-.058-.445-.141-.654-.206s-.438-.139-.654-.209c-.559-.181-3.783-1.23-3.919-1.237l0,.593c.216.09.67.211.916.291.32.1.622.191.937.292l3.722,1.174a3.255,3.255,0,0,0,1.01.243c.458,0,1.073.017,1.511,0a7.13,7.13,0,0,0-.8-.264"
                        transform="translate(-2.931 -1.449)"
                        fill="#ff1901"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_42"
                        data-name="Path 42"
                        d="M34.995,8.485l-.278.088-.343.115c.185.018,1.629.02,1.754,0,.219-.029.886-.283,1.155-.352,0-.037-.017-.027.057-.026.286-.07.728-.22,1.015-.313.229-.075.85-.285,1.05-.319l.063-.042c.233-.047.513-.153.748-.227s.519-.165.769-.241.49-.153.741-.233c.212-.067.578-.163.745-.24l.009-.594c-.24.01-6.446,2.082-7.484,2.38"
                        transform="translate(-8.153 -1.448)"
                        fill="#ff1901"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M36.1,13.578c.082,0,.066-.013.066.025.242.056.484.17.734.225.072,0,.054-.011.054.027.253.067.523.166.783.251s.5.161.793.252c.5.154,1.106.384,1.608.511l.045.026c.391.1.784.263,1.173.367.069,0,.053-.009.053.026.268.069.478.16.735.227.081,0,.066-.011.066.026l.268.075.007-.6L36.93,13.263a3.41,3.41,0,0,0-1.03-.25c-.442,0-1.066-.016-1.488.008.125.083,1.414.469,1.689.556"
                        transform="translate(-8.163 -3.086)"
                        fill="#ff1901"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M14.207,9l-1.827-.027,0-1.591c.04-.031-.063-.058.072-.012-.044-.035.076-.025-.121-.04l0,1.659Z"
                        transform="translate(-2.926 -1.739)"
                        fill="#5072b1"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M44.126,9h.664l.018-1.613c-.026-.058-.005-.024-.053-.052l.008,1.644c-.1,0-.205,0-.307,0l-.3.012c-.009,0-.023,0-.035.007"
                        transform="translate(-10.467 -1.739)"
                        fill="#5d6baf"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_46"
                        data-name="Path 46"
                        d="M34.814,9.3l.278-.088c-.05-.037.137-.063-.169-.027a.463.463,0,0,0-.142.043c.019.049,0,.034.033.072"
                        transform="translate(-8.25 -2.174)"
                        fill="#e3f4e5"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_47"
                        data-name="Path 47"
                        d="M37.729,14.109c0-.038.018-.03-.053-.027,0,.034-.015.024.053.027"
                        transform="translate(-8.937 -3.34)"
                        fill="#f74377"
                        fill-rule="evenodd"
                      />
                      <rect
                        id="Rectangle_48"
                        data-name="Rectangle 48"
                        width="24.888"
                        height="7.876"
                        transform="translate(9.427 4.653)"
                        fill="none"
                        stroke="#000"
                        strokeWidth="0.216"
                      />
                      <path
                        id="Path_48"
                        data-name="Path 48"
                        d="M11.509,5.25H37.694v9.173H11.509Zm.648,8.525H37.046V5.9H12.157Z"
                        transform="translate(-2.73 -1.246)"
                        fill="#01020b"
                        fill-rule="evenodd"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_66"
                    data-name="Group 66"
                    transform="translate(0 67.87)"
                  >
                    <path
                      id="Path_49"
                      data-name="Path 49"
                      d="M0,.245V28.667H12.136V24.595H4.072V.245Z"
                      transform="translate(0 -0.005)"
                      fill="#fff"
                    />
                    <g
                      id="Group_36"
                      data-name="Group 36"
                      transform="translate(0 0)"
                    >
                      <g
                        id="Group_35"
                        data-name="Group 35"
                        clip-path="url(#clip-path-2)"
                      >
                        <path
                          id="Path_50"
                          data-name="Path 50"
                          d="M14.831,6.946a7.225,7.225,0,0,1,.6-3.034A6.378,6.378,0,0,1,19.222.439,7.293,7.293,0,0,1,21.657,0a7.7,7.7,0,0,1,2.475.439,7.179,7.179,0,0,1,2.2,1.278,6.838,6.838,0,0,1,1.558,2.2,7.225,7.225,0,0,1,.6,3.034V21.955a7.158,7.158,0,0,1-.6,3.073,6.482,6.482,0,0,1-1.558,2.116,6.565,6.565,0,0,1-2.2,1.317,7.7,7.7,0,0,1-2.475.439,7.293,7.293,0,0,1-2.435-.439,5.87,5.87,0,0,1-2.2-1.317,6.054,6.054,0,0,1-1.6-2.116,7.158,7.158,0,0,1-.6-3.073ZM18.9,21.955a2.578,2.578,0,0,0,.838,2.155,2.721,2.721,0,0,0,1.916.719,2.888,2.888,0,0,0,1.956-.719,2.688,2.688,0,0,0,.8-2.155V6.946a2.838,2.838,0,0,0-.8-2.2,3.065,3.065,0,0,0-1.956-.679,2.882,2.882,0,0,0-1.916.679,2.717,2.717,0,0,0-.838,2.2Z"
                          transform="translate(-0.301 0)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_51"
                          data-name="Path 51"
                          d="M32.27,28.667V.244h3.912L42.33,17.369h.079V.244h4.072V28.667H42.649L36.422,11.581h-.08V28.667Z"
                          transform="translate(-0.655 -0.005)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_52"
                          data-name="Path 52"
                          d="M50.524,28.667V.245h6.027A7.067,7.067,0,0,1,61.9,2.16a7.613,7.613,0,0,1,1.8,5.429V20.883q0,4.012-1.916,5.868a7.654,7.654,0,0,1-5.588,1.916ZM54.6,4.077V24.835h1.876a2.924,2.924,0,0,0,2.435-.879,4.044,4.044,0,0,0,.719-2.675V7.589a4.368,4.368,0,0,0-.679-2.594,2.918,2.918,0,0,0-2.475-.918Z"
                          transform="translate(-1.025 -0.005)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_53"
                          data-name="Path 53"
                          d="M67.637,6.946a7.225,7.225,0,0,1,.6-3.034A6.378,6.378,0,0,1,72.028.439,7.293,7.293,0,0,1,74.463,0a7.7,7.7,0,0,1,2.475.439,7.179,7.179,0,0,1,2.2,1.278,6.82,6.82,0,0,1,1.557,2.2,7.209,7.209,0,0,1,.6,3.034V21.955a7.143,7.143,0,0,1-.6,3.073,6.465,6.465,0,0,1-1.557,2.116,6.565,6.565,0,0,1-2.2,1.317,7.7,7.7,0,0,1-2.475.439,7.293,7.293,0,0,1-2.435-.439,5.87,5.87,0,0,1-2.2-1.317,6.054,6.054,0,0,1-1.6-2.116,7.158,7.158,0,0,1-.6-3.073Zm4.072,15.009a2.578,2.578,0,0,0,.838,2.155,2.721,2.721,0,0,0,1.916.719,2.888,2.888,0,0,0,1.956-.719,2.688,2.688,0,0,0,.8-2.155V6.946a2.837,2.837,0,0,0-.8-2.2,3.065,3.065,0,0,0-1.956-.679,2.882,2.882,0,0,0-1.916.679,2.717,2.717,0,0,0-.838,2.2Z"
                          transform="translate(-1.372 0)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_54"
                          data-name="Path 54"
                          d="M85.076,28.667V.244h3.912l6.148,17.125h.079V.244h4.072V28.667H95.455L89.228,11.581h-.08V28.667Z"
                          transform="translate(-1.726 -0.005)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_55"
                          data-name="Path 55"
                          d="M112.6,28.667V.245h6.028a7.066,7.066,0,0,1,5.348,1.915,7.613,7.613,0,0,1,1.8,5.429V20.883q0,4.012-1.916,5.868a7.654,7.654,0,0,1-5.588,1.916Zm4.072-24.59V24.835h1.876a2.922,2.922,0,0,0,2.435-.879,4.044,4.044,0,0,0,.719-2.675V7.589a4.368,4.368,0,0,0-.679-2.594,2.918,2.918,0,0,0-2.475-.918Z"
                          transform="translate(-2.284 -0.005)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_56"
                          data-name="Path 56"
                          d="M129.955,28.667V.244h12.135V4.077h-8.063V12.42h7.026v3.832h-7.026v8.343h8.063v4.072Z"
                          transform="translate(-2.636 -0.005)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_57"
                          data-name="Path 57"
                          d="M158.187,8.423h-4.072V7.5a4.441,4.441,0,0,0-.639-2.436,2.462,2.462,0,0,0-2.236-1,2.723,2.723,0,0,0-1.357.319,2.308,2.308,0,0,0-.839.8,3.688,3.688,0,0,0-.439,1.157,7.96,7.96,0,0,0-.12,1.4,12.789,12.789,0,0,0,.079,1.4,2.642,2.642,0,0,0,.28,1,2.161,2.161,0,0,0,.718.758,5.814,5.814,0,0,0,1.237.639l3.114,1.237a8.577,8.577,0,0,1,2.2,1.2,5.664,5.664,0,0,1,1.318,1.636,8.51,8.51,0,0,1,.6,2.155,19.955,19.955,0,0,1,.16,2.8,14.3,14.3,0,0,1-.36,3.312,7.247,7.247,0,0,1-1.157,2.635,5.651,5.651,0,0,1-2.2,1.756,7.544,7.544,0,0,1-3.313.639,6.926,6.926,0,0,1-2.755-.518,7.006,7.006,0,0,1-2.2-1.437,6.812,6.812,0,0,1-1.437-2.156,6.362,6.362,0,0,1-.558-2.634V20.638h4.072v1.278a3.26,3.26,0,0,0,.679,2,2.534,2.534,0,0,0,2.2.918,3.717,3.717,0,0,0,1.636-.319,2.218,2.218,0,0,0,.879-.838,3.41,3.41,0,0,0,.4-1.358c.04-.518.04-1.078.04-1.716,0-.758-.04-1.357-.08-1.836a2.845,2.845,0,0,0-.319-1.158,3.065,3.065,0,0,0-.718-.718,7.111,7.111,0,0,0-1.237-.6l-2.914-1.2a6.371,6.371,0,0,1-3.513-2.874,9.607,9.607,0,0,1-.918-4.431,10.726,10.726,0,0,1,.439-3.034,6.4,6.4,0,0,1,1.318-2.475A6.525,6.525,0,0,1,148.327.6a7.724,7.724,0,0,1,5.868-.04,6.9,6.9,0,0,1,2.156,1.477,6.135,6.135,0,0,1,1.836,4.391Z"
                          transform="translate(-2.925 0)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <rect
                          id="Rectangle_58"
                          data-name="Rectangle 58"
                          width="4.072"
                          height="28.423"
                          transform="translate(158.415 0.239)"
                          fill="#fff"
                        />
                        <path
                          id="Path_58"
                          data-name="Path 58"
                          d="M169.64,6.945a7.223,7.223,0,0,1,.6-3.033A6.378,6.378,0,0,1,174.031.439,7.293,7.293,0,0,1,176.466,0a7.7,7.7,0,0,1,2.475.439,7.175,7.175,0,0,1,2.2,1.278,6.825,6.825,0,0,1,1.557,2.2,7.223,7.223,0,0,1,.6,3.033V8.382h-4.072V6.945a2.839,2.839,0,0,0-.8-2.2,3.065,3.065,0,0,0-1.956-.679,2.877,2.877,0,0,0-1.915.679,2.716,2.716,0,0,0-.839,2.2V21.954a2.576,2.576,0,0,0,.839,2.156,2.716,2.716,0,0,0,1.915.718,2.887,2.887,0,0,0,1.956-.718,2.689,2.689,0,0,0,.8-2.156V16.606h-3.233V13.013h7.3v8.942a7.163,7.163,0,0,1-.6,3.074,6.485,6.485,0,0,1-1.557,2.115,6.6,6.6,0,0,1-2.2,1.318,7.7,7.7,0,0,1-2.475.439,7.293,7.293,0,0,1-2.435-.439,5.885,5.885,0,0,1-2.2-1.318,6.059,6.059,0,0,1-1.6-2.115,7.163,7.163,0,0,1-.6-3.074Z"
                          transform="translate(-3.441 0)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_59"
                          data-name="Path 59"
                          d="M187.079,28.667V.244h3.912l6.148,17.125h.079V.244h4.072V28.667h-3.832L191.23,11.581h-.079V28.667Z"
                          transform="translate(-3.795 -0.005)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_60"
                          data-name="Path 60"
                          d="M212.484,24.812v1.333h-.275a1.244,1.244,0,0,0-.728.209.837.837,0,0,0-.3.732.975.975,0,0,0,.1.445.744.744,0,0,0,.238.274,1.049,1.049,0,0,0,.347.144,2.221,2.221,0,0,0,.418.039,3.515,3.515,0,0,0,.417-.026.737.737,0,0,0,.3-.091.679.679,0,0,0,.226-.235,2,2,0,0,0,.192-.406l.369-1.019a2.886,2.886,0,0,1,.359-.719,1.719,1.719,0,0,1,.488-.431,2.363,2.363,0,0,1,.645-.2,5.323,5.323,0,0,1,.836-.053,3.912,3.912,0,0,1,.99.118,2.086,2.086,0,0,1,.788.379,1.835,1.835,0,0,1,.525.719,2.677,2.677,0,0,1,.19,1.085,2.485,2.485,0,0,1-.154.9,2.293,2.293,0,0,1-.431.719,2.006,2.006,0,0,1-.644.47,1.758,1.758,0,0,1-.788.183h-.454V28.053h.382a.928.928,0,0,0,.6-.221.866.866,0,0,0,.274-.719,1.333,1.333,0,0,0-.1-.536.705.705,0,0,0-.252-.287.954.954,0,0,0-.407-.131c-.155-.013-.321-.013-.511-.013-.228,0-.406.013-.551.026a.8.8,0,0,0-.345.1.968.968,0,0,0-.216.235,2.533,2.533,0,0,0-.179.406l-.357.954a2.06,2.06,0,0,1-.859,1.15,2.667,2.667,0,0,1-1.326.3,2.96,2.96,0,0,1-.906-.143,1.875,1.875,0,0,1-.74-.432,2.117,2.117,0,0,1-.5-.705,2.549,2.549,0,0,1-.179-1.006,2.516,2.516,0,0,1,.169-.915,2.239,2.239,0,0,1,.441-.705,1.754,1.754,0,0,1,1.312-.6Z"
                          transform="translate(-4.259 -0.504)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_61"
                          data-name="Path 61"
                          d="M218.535,22.845h-7.351v1.542h-1.145V19.97h1.145v1.542h7.351Z"
                          transform="translate(-4.26 -0.405)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_62"
                          data-name="Path 62"
                          d="M210.038,14.967h6.587a1.841,1.841,0,0,1,.764.157,2.117,2.117,0,0,1,.632.47,2.353,2.353,0,0,1,.43.693,2.173,2.173,0,0,1,.155.837,2.066,2.066,0,0,1-.155.824,2.366,2.366,0,0,1-.43.693,2.117,2.117,0,0,1-.632.47,1.655,1.655,0,0,1-.764.169h-6.587V17.947h6.468a.867.867,0,0,0,.668-.236.913.913,0,0,0,0-1.176.871.871,0,0,0-.668-.235h-6.468Z"
                          transform="translate(-4.26 -0.304)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <path
                          id="Path_63"
                          data-name="Path 63"
                          d="M218.535,13.6h-8.5V11.624a2.441,2.441,0,0,1,.572-1.751,2.154,2.154,0,0,1,1.623-.589h3.974a2.227,2.227,0,0,1,1.754.628,2.653,2.653,0,0,1,.573,1.829Zm-7.351-1.333h6.2V11.65a1.011,1.011,0,0,0-.262-.8,1.138,1.138,0,0,0-.8-.235h-4.092a1.222,1.222,0,0,0-.776.222,1.007,1.007,0,0,0-.274.81Z"
                          transform="translate(-4.26 -0.189)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <rect
                          id="Rectangle_59"
                          data-name="Rectangle 59"
                          width="8.496"
                          height="1.333"
                          transform="translate(205.778 6.469)"
                          fill="#fff"
                        />
                        <path
                          id="Path_64"
                          data-name="Path 64"
                          d="M212.042,5.27a1.991,1.991,0,0,1-.906-.2,1.928,1.928,0,0,1-.658-.523,2.116,2.116,0,0,1-.38-.719,2.562,2.562,0,0,1-.132-.8,2.723,2.723,0,0,1,.132-.811,2.4,2.4,0,0,1,.38-.718A2.041,2.041,0,0,1,211.136,1a1.979,1.979,0,0,1,.906-.2h4.487a1.973,1.973,0,0,1,.918.2,1.936,1.936,0,0,1,.632.509,2.2,2.2,0,0,1,.394.718,2.753,2.753,0,0,1,.131.811,2.589,2.589,0,0,1-.131.8,1.958,1.958,0,0,1-.394.719,1.834,1.834,0,0,1-.632.523,1.985,1.985,0,0,1-.918.2Zm4.487-1.333a.738.738,0,0,0,.644-.274.943.943,0,0,0,.216-.627,1,1,0,0,0-.216-.641.766.766,0,0,0-.644-.262h-4.487a.807.807,0,0,0-.656.262,1.065,1.065,0,0,0-.2.641,1,1,0,0,0,.2.627.777.777,0,0,0,.656.274Z"
                          transform="translate(-4.259 -0.017)"
                          fill="#fff"
                          fill-rule="evenodd"
                        />
                        <rect
                          id="Rectangle_60"
                          data-name="Rectangle 60"
                          width="2.276"
                          height="28.449"
                          transform="translate(201.692 0.187)"
                          fill="#fff"
                        />
                        <rect
                          id="Rectangle_61"
                          data-name="Rectangle 61"
                          width="2.276"
                          height="28.449"
                          transform="translate(215.824 0.187)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </Link>
            </div>
          </div>
          <div className="col-lg-2">
            <div class="footer-content-wrapper">
              <Link to="/aboutus">
                <h6 className="footer-area-lnk-clr footer-responsive-padding">
                  About Us
                </h6>
              </Link>
            </div>
          </div>

          <div className="col-lg-1">
            <div class="footer-content-wrapper">
              <Link to="/blog">
                <h6 className="footer-area-lnk-clr footer-responsive-padding">
                  Blog
                </h6>
              </Link>
            </div>
          </div>
          <div className="col-lg-2">
            <div class="footer-content-wrapper">
              <Link to="/policy">
                <h6 className="footer-area-lnk-clr footer-responsive-padding">
                  Privacy Policy
                </h6>
              </Link>
            </div>
          </div>

          <div className="col-lg-2">
            <div class="footer-content-wrapper">
              <Link to="/terms-conditions">
                <h6 className="footer-area-lnk-clr footer-responsive-padding">
                  Terms & Conditions
                </h6>
              </Link>
            </div>
          </div>

          <div className="col-lg-1">
            <div class="footer-content-wrapper">
              <Link to="/refund-policy">
                <h6 className="footer-area-lnk-clr footer-responsive-padding">
                  Refund Policy
                </h6>
              </Link>
            </div>
          </div>

          <div className="col-lg-2">
            <div className="footer-soial-action">
              <Link to="/#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.849"
                  height="21.85"
                  viewBox="0 0 21.849 21.85"
                >
                  <path
                    id="Icon_awesome-twitter-square"
                    data-name="Icon awesome-twitter-square"
                    d="M19.508,2.25H2.341A2.342,2.342,0,0,0,0,4.591V21.758A2.342,2.342,0,0,0,2.341,24.1H19.508a2.342,2.342,0,0,0,2.341-2.341V4.591A2.342,2.342,0,0,0,19.508,2.25ZM17.124,9.995c.01.137.01.278.01.415a9.039,9.039,0,0,1-9.1,9.1,9.059,9.059,0,0,1-4.911-1.434,6.739,6.739,0,0,0,.771.039,6.413,6.413,0,0,0,3.97-1.366,3.2,3.2,0,0,1-2.99-2.219,3.447,3.447,0,0,0,1.444-.059,3.2,3.2,0,0,1-2.56-3.141v-.039a3.2,3.2,0,0,0,1.444.4A3.192,3.192,0,0,1,3.775,9.034,3.159,3.159,0,0,1,4.209,7.42,9.082,9.082,0,0,0,10.8,10.765,3.205,3.205,0,0,1,16.26,7.844a6.268,6.268,0,0,0,2.029-.771,3.19,3.19,0,0,1-1.4,1.761,6.365,6.365,0,0,0,1.844-.5A6.733,6.733,0,0,1,17.124,9.995Z"
                    transform="translate(0 -2.25)"
                    fill="#fff"
                  />
                </svg>
              </Link>

              <Link to="/#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.398"
                  height="21.397"
                  viewBox="0 0 21.398 21.397"
                >
                  <path
                    id="Icon_metro-instagram"
                    data-name="Icon metro-instagram"
                    d="M20.416,1.928H6.123A3.563,3.563,0,0,0,2.571,5.48V19.773a3.563,3.563,0,0,0,3.552,3.552H20.416a3.563,3.563,0,0,0,3.552-3.552V5.48A3.563,3.563,0,0,0,20.416,1.928Zm-11,9.361h7.7a4.075,4.075,0,1,1-7.7,0Zm11.873,0v8.023a1.341,1.341,0,0,1-1.337,1.337H6.583a1.341,1.341,0,0,1-1.337-1.337V11.289H7.337a6.081,6.081,0,1,0,11.864,0h2.092Zm0-4.682a.671.671,0,0,1-.669.669H19.288a.671.671,0,0,1-.669-.669V5.271a.671.671,0,0,1,.669-.669h1.337a.671.671,0,0,1,.669.669Z"
                    transform="translate(-2.571 -1.928)"
                    fill="#fff"
                  />
                </svg>
              </Link>
              <a href="https://www.linkedin.com/company/ldsl" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.397"
                  height="21.397"
                  viewBox="0 0 21.397 21.397"
                >
                  <path
                    id="Icon_ionic-logo-linkedin"
                    data-name="Icon ionic-logo-linkedin"
                    d="M24.186,4.5H6.328A1.746,1.746,0,0,0,4.5,6.167v17.9A1.883,1.883,0,0,0,6.328,25.9H24.181A1.787,1.787,0,0,0,25.9,24.064V6.167A1.641,1.641,0,0,0,24.186,4.5ZM11.133,22.336H8.067V12.8h3.066ZM9.706,11.356H9.684A1.573,1.573,0,0,1,8.067,9.711,1.583,1.583,0,0,1,9.723,8.067a1.579,1.579,0,0,1,1.639,1.644A1.583,1.583,0,0,1,9.706,11.356Zm12.63,10.98H19.27V17.124c0-1.249-.446-2.1-1.555-2.1a1.677,1.677,0,0,0-1.572,1.131,2.064,2.064,0,0,0-.106.752v5.429H12.972V12.8h3.066v1.327A3.1,3.1,0,0,1,18.8,12.582c2.012,0,3.534,1.327,3.534,4.186v5.568Z"
                    transform="translate(-4.5 -4.5)"
                    fill="#fff"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div class="col-lg-12">
            <div class="payment-method-footer-area footer-responsive-padding">
              <img
                src={process.env.PUBLIC_URL + "/img/payment-method-footer.png"}
                alt="payment"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div class="footer-copyright footer-responsive-padding">
            <p>
              Copyright © 2010-2022 IDS. All rights reserved. Developed by{" "}
              <a href="https://solutionspin.com" target="_blank">
                SolutionSpin Ltd.
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
