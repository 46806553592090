import React from "react";
import Fade from "react-reveal/Fade";

export default function Mission() {
  return (
    <div className="mission-area long-section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="market-faster-img">
              <Fade right>
                <img src="img/mission-img.png" alt="market-faster-img" />
              </Fade>

              <div className="mission-image-shape"></div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="mission-content">
              <div className="section-title">
                <h2 className="pb-25">Our Mission</h2>
                <h4 className="mission-sub-title pb-40">
                  To provide a comprehensive digitized apparel supply chain
                  platform which enables stakeholders to operate with
                  transparency
                </h4>
                <div>
                  <div className="mission-content">
                    <i className="fa-solid fa-circle-dot"></i>
                    <h6>Digitized Swatch Selection</h6>
                  </div>
                  <div className="mission-content">
                    <i className="fa-solid fa-circle-dot"></i>
                    <h6>
                      Agile tech packs with real-time monitoring and information
                    </h6>
                  </div>
                  <div className="mission-content">
                    <i className="fa-solid fa-circle-dot"></i>
                    <h6>Dedicated in-house LDS team monitoring progress</h6>
                  </div>
                  <div className="mission-content">
                    <i className="fa-solid fa-circle-dot"></i>
                    <h6>Shipments with Tracking Facility</h6>
                  </div>
                  <div className="mission-content">
                    <i className="fa-solid fa-circle-dot"></i>
                    <h6>
                      Ready reckoner platform with certified fabric &
                      accessories suppliers
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
