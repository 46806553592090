import React, { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Bounce from "react-reveal/Bounce";

const Navbar = () => {
  const [show, setShow] = useState("false");
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 150) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const raiseInvoiceClicked = () => {
    // your axios call here
    localStorage.setItem("pageData", "Data Retrieved from axios request");
    // route to new page by changing window.location
    window.open("https://app.dgarma.com", "_blank"); //to open new page
  };
  const handleClick = () => {
    if (show === "true") {
      setShow("false");
    }

    if (show === "false") {
      setShow("true");
    }
  };

  return (
    <>
      <div
        className={
          scrolled
            ? "Regular shadow fixed-top black-word  bg-light"
            : "navbar-area"
        }
      >
        <div className="container">
          <div className="row align-items-center">
            <div class="mobile-btn text-right">
              <button onClick={handleClick}>
                <i class="fas fa-bars"></i>
              </button>
            </div>
            <div className="col-lg-3 col-md-6">
              {scrolled ? (
                <div className="top-menu-bar-logo-two">
                  <Link to="/">
                    <img src="img/logo.png" alt="img" />
                  </Link>
                </div>
              ) : (
                <div className="top-menu-bar-logo">
                  <Link to="/">
                    <img src="img/lds-icon/lds-logo-part-one.svg" alt="logo" />
                    <img src="img/lds-icon/lds-logo.svg" alt="logo" />
                  </Link>
                </div>
              )}
            </div>

            <div className="col-lg-9 col-md-6 destop-menu ">
              <div className="top-menu-bar-content">
                <ul>
                  <Link className="footer-area-lnk-clr" to="/stock-fabrics">
                    <li>Stock Fabrics</li>
                  </Link>
                  <Link className="footer-area-lnk-clr" to="/tech-pack">
                    <li>TechPacks</li>
                  </Link>
                  <Link className="footer-area-lnk-clr" to="/aboutus">
                    <li> About Us </li>
                  </Link>
                  <Link className="footer-area-lnk-clr" to="/career">
                    <li> Career</li>
                  </Link>

                  <Link className="footer-area-lnk-clr" to="/contactus">
                    <li>Contact Us </li>
                  </Link>

                  <Link className="footer-area-lnk-clr" to="/blog">
                    <li>Blog</li>
                  </Link>

                  <li className="navbar-login scroll-login">
                    <a
                      className="text-light"
                      href="https://app.dgarma.com"
                      target="_blank"
                    >
                      Log in
                    </a>
                  </li>

                  <li className="navbar-explore-btn scroll-login">
                    <a
                      className="text-light"
                      href="https://dgarma.com"
                      target="_blank"
                    >
                      Connect DGarma
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {show === "true" ? (
              <Bounce top>
                <div>
                  <div class="mobile-navbar-area">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="mobile-navbar">
                            <div class="mobile-btn text-right">
                              <button onClick={handleClick}>
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                            <div class="mobile-navbar-content">
                              <ul>
                                <Link
                                  className="footer-area-lnk-clr"
                                  to="/stock-fabrics"
                                >
                                  <li>Stock Fabrics</li>
                                </Link>
                                <Link
                                  className="footer-area-lnk-clr"
                                  to="/tech-pack"
                                >
                                  <li>TechPacks</li>
                                </Link>
                                <li>
                                  <Link
                                    className="footer-area-lnk-clr"
                                    to="/aboutus"
                                  >
                                    About Us
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    className="footer-area-lnk-clr"
                                    to="/career"
                                  >
                                    Career
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    className="footer-area-lnk-clr"
                                    to="/contactus"
                                  >
                                    Contact Us
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    className="footer-area-lnk-clr"
                                    to="/blog"
                                  >
                                    Blog
                                  </Link>
                                </li>

                                <li onClick={raiseInvoiceClicked}>Log in</li>

                                <li className="navbar-explore-btn scroll-login">
                                  <a
                                    className="text-light"
                                    href="https://dgarma.com"
                                    target="_blank"
                                  >
                                    Connect DGarma
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Bounce>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Navbar;
