import React from "react";
import Fade from "react-reveal/Fade";
import Spin from "react-reveal/Spin";

const AugmentResources = () => {
  return (
    <>
      <div className="augment-resources-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title  long-section-padding">
                <h2>Augment Resources, Accelerate Output</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="augment-resources-content-area">
                <div className="augment-resources-content">
                  <div className="augment-resources-icon">
                    <Spin>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="70"
                        height="70"
                        viewBox="0 0 70 70"
                      >
                        <defs>
                          <filter
                            id="Ellipse_25"
                            x="0"
                            y="0"
                            width="70"
                            height="70"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="6" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood floodOpacity="0.071" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_3_1"
                          data-name="Component 3 – 1"
                          transform="translate(12 6)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, -12, -6)"
                            filter="url(#Ellipse_25)"
                          >
                            <circle
                              id="Ellipse_25-2"
                              data-name="Ellipse 25"
                              cx="23"
                              cy="23"
                              r="23"
                              transform="translate(12 6)"
                              fill="#fff"
                            />
                          </g>
                          <path
                            id="Icon_awesome-angle-double-right"
                            data-name="Icon awesome-angle-double-right"
                            d="M7.974,12.278,3.726,16.526a.747.747,0,0,1-1.059,0L1.96,15.82a.747.747,0,0,1,0-1.059L4.972,11.75,1.96,8.738a.747.747,0,0,1,0-1.059l.7-.712a.747.747,0,0,1,1.059,0l4.249,4.249a.748.748,0,0,1,0,1.062Zm6-1.062L9.724,6.967a.747.747,0,0,0-1.059,0l-.706.706a.747.747,0,0,0,0,1.059l3.012,3.012L7.959,14.755a.747.747,0,0,0,0,1.059l.706.706a.747.747,0,0,0,1.059,0l4.249-4.249A.743.743,0,0,0,13.972,11.215Z"
                            transform="translate(15.033 11.254)"
                          />
                        </g>
                      </svg>
                    </Spin>
                    <div className="vertical-line"></div>
                  </div>

                  <div className="augment-resources-content-item">
                    <h3>Extensive Certified Fabrics from Trusted Factories</h3>
                  </div>
                </div>

                <div className="augment-resources-content">
                  <div className="augment-resources-icon">
                    <Spin>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="70"
                        height="70"
                        viewBox="0 0 70 70"
                      >
                        <defs>
                          <filter
                            id="Ellipse_25"
                            x="0"
                            y="0"
                            width="70"
                            height="70"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="6" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood floodOpacity="0.071" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_3_1"
                          data-name="Component 3 – 1"
                          transform="translate(12 6)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, -12, -6)"
                            filter="url(#Ellipse_25)"
                          >
                            <circle
                              id="Ellipse_25-2"
                              data-name="Ellipse 25"
                              cx="23"
                              cy="23"
                              r="23"
                              transform="translate(12 6)"
                              fill="#fff"
                            />
                          </g>
                          <path
                            id="Icon_awesome-angle-double-right"
                            data-name="Icon awesome-angle-double-right"
                            d="M7.974,12.278,3.726,16.526a.747.747,0,0,1-1.059,0L1.96,15.82a.747.747,0,0,1,0-1.059L4.972,11.75,1.96,8.738a.747.747,0,0,1,0-1.059l.7-.712a.747.747,0,0,1,1.059,0l4.249,4.249a.748.748,0,0,1,0,1.062Zm6-1.062L9.724,6.967a.747.747,0,0,0-1.059,0l-.706.706a.747.747,0,0,0,0,1.059l3.012,3.012L7.959,14.755a.747.747,0,0,0,0,1.059l.706.706a.747.747,0,0,0,1.059,0l4.249-4.249A.743.743,0,0,0,13.972,11.215Z"
                            transform="translate(15.033 11.254)"
                          />
                        </g>
                      </svg>
                    </Spin>

                    <div className="vertical-line"></div>
                  </div>

                  <div className="augment-resources-content-item">
                    <h3>Digitizing End-to-End Sourcing Solutions</h3>
                  </div>
                </div>

                <div className="augment-resources-content">
                  <div className="augment-resources-icon">
                    <Spin>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="70"
                        height="70"
                        viewBox="0 0 70 70"
                      >
                        <defs>
                          <filter
                            id="Ellipse_25"
                            x="0"
                            y="0"
                            width="70"
                            height="70"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="6" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood floodOpacity="0.071" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_3_1"
                          data-name="Component 3 – 1"
                          transform="translate(12 6)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, -12, -6)"
                            filter="url(#Ellipse_25)"
                          >
                            <circle
                              id="Ellipse_25-2"
                              data-name="Ellipse 25"
                              cx="23"
                              cy="23"
                              r="23"
                              transform="translate(12 6)"
                              fill="#fff"
                            />
                          </g>
                          <path
                            id="Icon_awesome-angle-double-right"
                            data-name="Icon awesome-angle-double-right"
                            d="M7.974,12.278,3.726,16.526a.747.747,0,0,1-1.059,0L1.96,15.82a.747.747,0,0,1,0-1.059L4.972,11.75,1.96,8.738a.747.747,0,0,1,0-1.059l.7-.712a.747.747,0,0,1,1.059,0l4.249,4.249a.748.748,0,0,1,0,1.062Zm6-1.062L9.724,6.967a.747.747,0,0,0-1.059,0l-.706.706a.747.747,0,0,0,0,1.059l3.012,3.012L7.959,14.755a.747.747,0,0,0,0,1.059l.706.706a.747.747,0,0,0,1.059,0l4.249-4.249A.743.743,0,0,0,13.972,11.215Z"
                            transform="translate(15.033 11.254)"
                          />
                        </g>
                      </svg>
                    </Spin>

                    <div className="vertical-line"></div>
                  </div>

                  <div className="augment-resources-content-item">
                    <h3 className="augment-resources-content-item-special-margin">
                      Go from conception to collection in lesser time / replace
                      procurement times with delivery times
                    </h3>
                  </div>
                </div>

                <div className="augment-resources-content">
                  <div className="augment-resources-icon">
                    <Spin>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="70"
                        height="70"
                        viewBox="0 0 70 70"
                      >
                        <defs>
                          <filter
                            id="Ellipse_25"
                            x="0"
                            y="0"
                            width="70"
                            height="70"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="6" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood floodOpacity="0.071" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_3_1"
                          data-name="Component 3 – 1"
                          transform="translate(12 6)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, -12, -6)"
                            filter="url(#Ellipse_25)"
                          >
                            <circle
                              id="Ellipse_25-2"
                              data-name="Ellipse 25"
                              cx="23"
                              cy="23"
                              r="23"
                              transform="translate(12 6)"
                              fill="#fff"
                            />
                          </g>
                          <path
                            id="Icon_awesome-angle-double-right"
                            data-name="Icon awesome-angle-double-right"
                            d="M7.974,12.278,3.726,16.526a.747.747,0,0,1-1.059,0L1.96,15.82a.747.747,0,0,1,0-1.059L4.972,11.75,1.96,8.738a.747.747,0,0,1,0-1.059l.7-.712a.747.747,0,0,1,1.059,0l4.249,4.249a.748.748,0,0,1,0,1.062Zm6-1.062L9.724,6.967a.747.747,0,0,0-1.059,0l-.706.706a.747.747,0,0,0,0,1.059l3.012,3.012L7.959,14.755a.747.747,0,0,0,0,1.059l.706.706a.747.747,0,0,0,1.059,0l4.249-4.249A.743.743,0,0,0,13.972,11.215Z"
                            transform="translate(15.033 11.254)"
                          />
                        </g>
                      </svg>
                    </Spin>
                  </div>

                  <div className="augment-resources-content-item">
                    <h3 className="augment-resources-content-item-special-margin-responsive">
                      Get access to expert designers from across the globe
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="augment-resources-img">
                <Fade left>
                  <img
                    src="img/augment-resources-img.png"
                    alt="augment-resources-img"
                  />
                </Fade>

                <div className="augment-resources-antiqueWhite1-shape"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AugmentResources;
