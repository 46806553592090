import React from "react";

export default function Career() {
  return (
    <>
      <div class="container">
        <br></br>
        <br></br>

        <br></br>
        <h1 className="text-white text-center">Currently we have no Openings</h1>
        <br></br>
        <br></br>

        <br></br>

        <br></br>
        <br></br>

        <br></br>

        <br></br>
        <br></br>

        <br></br>

        <br></br>
        <br></br>

        <br></br>
        <br></br>
        <br></br>

        <br></br>

        <br></br>
        <br></br>

        <br></br>
        <br></br>
        <br></br>

        <br></br>

        <br></br>
        <br></br>

        <br></br>
      </div>
    </>
  );
}
